import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import _ from "lodash";
import { setError } from "src/redux/slices/errorSlice";

import { budgetsKeyFactory } from "@features/budgets";
import { Order } from "@models/Order";
import { OrderSet } from "@models/OrderSet";
import { OrderVariant } from "@models/OrderVariant";
import {
  removeOrderVariantError,
  setOrderVariantError,
} from "@redux/slices/orders/orderSetSlice";
import client from "@services/api";

import useOrderSetId from "../../useOrderSetId";
import { orderSetsKeyFactory } from "../orderSetQueries";

const calcNewOrderVariantCosts = (ov: OrderVariant, newPrice: number) => {
  const derivedTaxRate = +ov.totalEstimatedTax / +ov.totalPrice || 0;
  const derivedShippingRate =
    +ov.totalEstimatedShippingCost / +ov.totalPrice || 0;
  const newTotalPrice = ov.qty * newPrice;

  return {
    price: String(newPrice),
    totalPrice: String(newTotalPrice),
    totalEstimatedTax: String(newTotalPrice * derivedTaxRate),
    totalEstimatedShippingCost: String(newTotalPrice * derivedShippingRate),
  };
};

export default function useSetOrderVariantQty() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const orderSetId = useOrderSetId();

  const startOrderSet = useCallback(async () => {
    client.post(`order-sets/${orderSetId}/start`).catch((err) => {
      console.error(err);
      queryClient.invalidateQueries({
        queryKey: orderSetsKeyFactory.detail(orderSetId).queryKey,
      });
    });
    // Optimistic update
    queryClient.setQueryData(
      orderSetsKeyFactory.detail(orderSetId).queryKey,
      (orderSet: OrderSet) => ({
        ...orderSet,
        status: "in-progress",
      })
    );
  }, [orderSetId, queryClient]);

  return useMutation({
    mutationFn: ({ id, qty }: { id: string; qty: number }) => {
      const orderSet = queryClient.getQueryData(
        orderSetsKeyFactory.detail(orderSetId).queryKey
      ) as OrderSet;

      if (orderSet.status === "inactive") {
        startOrderSet();
      }

      return client
        .update<OrderVariant>(`order-variants/${id}`, {
          type: "order-variant",
          qty,
        })
        .then((data) => ({ orderVariant: data.data, orderSet }));
    },
    onSuccess: ({ orderVariant: newOrderVariant, orderSet }) => {
      dispatch(removeOrderVariantError({ id: newOrderVariant.id }));

      queryClient.invalidateQueries({
        queryKey: budgetsKeyFactory.detail._def,
      });

      const osVariant = orderSet.orderSetVariants.find(
        (osv) => osv.variant.id === newOrderVariant.variant.id
      )!;

      // osv doesn't have a price from the api response, but we set it in this mutation for reference
      const pricingTierChange =
        (osVariant as any).price !== newOrderVariant.price;

      if (pricingTierChange) {
        queryClient.setQueryData<OrderSet>(
          orderSetsKeyFactory.detail(orderSetId).queryKey,
          (orderSet) =>
            orderSet && {
              ...orderSet,
              orderSetVariants: orderSet.orderSetVariants.map((osv) => {
                if (osv.variant.id === newOrderVariant.variant.id) {
                  return {
                    ...osv,
                    price: newOrderVariant.price,
                  };
                }
                return osv;
              }),
            }
        );
      }

      return queryClient.setQueryData<Order[]>(
        orderSetsKeyFactory.detail(orderSetId)._ctx.orders.queryKey,
        (orders) => {
          return (
            orders &&
            orders.map((order) => {
              let replacedOVCopy: any;
              let modifiedOV = newOrderVariant;
              const orderVariants = order.orderVariants.map((ov) => {
                // We're replacing this order-variant with the new updated one.
                if (ov.id === newOrderVariant.id) {
                  replacedOVCopy = ov;
                  return newOrderVariant;
                }

                // The pricing tier of the variant for this order set has changed,
                // all order-variants for this variant need to be updated
                if (
                  pricingTierChange &&
                  ov.variant.id === newOrderVariant.variant.id
                ) {
                  replacedOVCopy = ov;
                  modifiedOV = {
                    ...ov,
                    ...calcNewOrderVariantCosts(ov, +newOrderVariant.price),
                  };
                  return modifiedOV;
                }
                return ov;
              });

              let updatedOrderTotals = {};

              if (replacedOVCopy) {
                const updateKey = (key: string) =>
                  String(+order[key] - +replacedOVCopy[key] + +modifiedOV[key]);

                updatedOrderTotals = {
                  totalQuantity:
                    order.totalQuantity - replacedOVCopy.qty + modifiedOV.qty,
                  totalPrice: +updateKey("totalPrice"),
                  totalEstimatedTax: +updateKey("totalEstimatedTax"),
                  totalEstimatedShippingCost: +updateKey(
                    "totalEstimatedShippingCost"
                  ),
                };
              }

              return {
                ...order,
                ...updatedOrderTotals,
                orderVariants,
              };
            })
          );
        }
      );
    },
    onError: (error: any, { id }) => {
      if (Array.isArray(error.data.errors)) {
        const message = error.data.errors[0]?.title;
        // matches a number in parentheses at the end of the string
        const maxQuantity = message.match(/\((\d+)\)$/)?.[1];
        dispatch(setOrderVariantError({ id, error: message, maxQuantity }));
      } else {
        dispatch(
          setError({
            error: error.message,
            source: "useSetOrderVariantQty",
          })
        );
        console.error(error);
      }
    },
  });
}
