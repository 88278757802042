/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect, useState } from "react";
import React from "react";
import { Link } from "react-router";

import { Button, CircularProgress } from "@mui/material";

import _ from "lodash";

import AddressModal from "@components/Profile/Addresses/AddressModal";
import { AddressSearch } from "@features/addresses";

import OrderingHelpIcon from "../../../OrderingHelpIcon";
import { useCurrentOrderSet } from "../../CurrentOrderSetContext";
import useAddOrders from "../../data/mutations/useAddOrders";
import FavoriteAddressSetsMenuButton from "./FavoriteAddressSetsMenuButton";

const OrderSetAddressSection = () => {
  const addOrders = useAddOrders();
  const { orders } = useCurrentOrderSet();
  const [queuedAddressIds, setQueuedAddressIds] = useState<string[]>([]);
  const [creatingNewAddress, setCreatingNewAddress] = useState(false);

  const handleCreateOrders = (addressIds: string[]) => {
    setQueuedAddressIds([...queuedAddressIds, ...addressIds]);
    addOrders.mutate(addressIds, {
      onError: () => setQueuedAddressIds((ids) => _.xor(ids, addressIds)),
    });
  };

  const orderSetAddressIds = orders.flatMap((o) =>
    o.address ? [o.address.id] : []
  );
  const loadingOrders = _.difference(queuedAddressIds, orderSetAddressIds);
  const isLoadingOrders = loadingOrders.length > 0;

  useEffect(() => {
    if (!isLoadingOrders) {
      setQueuedAddressIds([]);
    }
  }, [isLoadingOrders]);

  return (
    <div>
      <div tw="flex justify-between items-center -mt-2  mb-2">
        <h3 tw="font-medium">Add Addresses</h3>
        <OrderingHelpIcon />
      </div>
      {creatingNewAddress && (
        <AddressModal
          open
          type="new"
          handleClose={() => setCreatingNewAddress(false)}
          onSuccess={(a) => handleCreateOrders([a.id])}
        />
      )}
      <div tw="space-y-3">
        <AddressSearch
          multiple
          validateTerritory
          autoFocus
          onChange={(addresses) =>
            handleCreateOrders(
              [addresses.at(-1)?.id].filter(Boolean) as string[]
            )
          }
          renderTags={() => null}
          getOptionDisabled={(option) =>
            orderSetAddressIds.includes(option.id) ||
            queuedAddressIds.includes(option.id)
          }
        />
        <div tw="flex gap-2 mt-3 [&>*]:flex-1">
          <FavoriteAddressSetsMenuButton
            handleAddAddressSet={handleCreateOrders}
          />
          <Button
            tw="whitespace-nowrap"
            variant="outlined"
            aria-haspopup="true"
            onClick={() => setCreatingNewAddress(true)}
          >
            + New Address
          </Button>
        </div>
        {isLoadingOrders && (
          <div tw="bg-neutral-100 px-3 py-1.5 rounded-lg text-neutral-700 tracking-wide flex items-center justify-between">
            <div>Creating orders...</div>
            <CircularProgress size={16} />
          </div>
        )}
        {!isLoadingOrders && (
          <div tw="pt-3">
            <Link tw="text-primary-800 underline" to="/profile/addressBook">
              Manage Address Book
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderSetAddressSection;
