/** @jsxImportSource @emotion/react */
import "twin.macro";

import { Skeleton } from "@mui/material";

export const ItemModalSkeleton = () => (
  <div tw="grid grid-cols-2 gap-4 p-4">
    <div tw="flex items-center justify-center p-4">
      <Skeleton variant="rounded" width={300} height={300} />
    </div>
    <div>
      <div tw="max-w-prose mb-12 p-4">
        <div tw="grid grid-cols-3 items-center gap-2">
          <Skeleton variant="text" width={100} height={20} />
          <Skeleton variant="text" width={100} height={20} />
        </div>
        <Skeleton variant="text" width="60%" height={40} tw="mt-4" />
        <Skeleton tw="mt-3" variant="text" width={80} height={30} />

        {/* Description */}
        <Skeleton variant="text" width="40%" height={30} tw="mt-6" />
        <div>
          <Skeleton variant="text" width="60%" height={20} />
          <Skeleton variant="text" width="60%" height={20} />
        </div>

        {/* Categories */}
        <Skeleton variant="text" width="40%" height={30} tw="mt-6" />
        <div tw="flex gap-2">
          <Skeleton variant="text" width={100} height={60} />
          <Skeleton variant="text" width={140} height={60} />
        </div>

        {/* Item info */}
        <Skeleton variant="text" width="40%" height={30} tw="mt-6" />
        <div>
          <Skeleton variant="text" width={120} height={20} />
          <Skeleton variant="text" width={140} height={20} />
          <Skeleton variant="text" width={100} height={20} />
        </div>
        <Skeleton variant="text" width="40%" height={30} tw="mt-6" />
      </div>
    </div>
  </div>
);
