import React from "react";
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router";

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://ac0d29b5b0eabf6b29a0492720cc93b8@o555075.ingest.us.sentry.io/4508648373026816",
  environment: process.env.VITE_DEV_ENV,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.extraErrorDataIntegration({
      depth: 3,
    }),
    Sentry.httpClientIntegration({
      failedRequestStatusCodes: [
        [403, 404],
        [500, 599],
      ],
    }),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [/^\/api/],
  sendDefaultPii: true,
});

export const sentryCreateBrowserRouterV7 =
  Sentry.wrapCreateBrowserRouterV7(createBrowserRouter);
