/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { Cancel } from "@mui/icons-material";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import _ from "lodash";
import PropTypes from "prop-types";

import { getItemPreviewImage } from "@features/items";
import { CreateInventoryPOVModal } from "@features/purchaseOrders";
import { setItemPreview } from "@redux/slices/items/itemSlice";
import {
  deletePurchaseOrder,
  deletePurchaseOrderVariant,
  updatePurchaseOrderVariant,
} from "@redux/slices/purchaseOrders/purchaseOrderSlice";
import { CldImage } from "@services/cloudinary";
import { useLastLocation } from "@services/reactRouterDom";
import { formatMoney } from "@utility/utilityFunctions";
import { moneyAdornment } from "@utils/forms";
import permissions from "@utils/permissions";

import { StyledButton } from "../StyledComponents";
import DebounceTextField from "../Utility/DebounceTextField";
import StandardTableHead from "../Utility/StandardTableHead";
import PurchaseOrderVariantTableFooter from "./PurchaseOrderVariantTableFooter";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const PurchaseOrderVariantTable = ({ handleSetupFeeOpen }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const lastLocation = useLastLocation();
  const backUrl = lastLocation
    ? lastLocation.pathname + lastLocation.search
    : "/purchasing/purchase-order-rollup";

  const {
    currentPurchaseOrder: { purchaseOrderVariants, status, id },
    isLoading,
  } = useSelector((state) => state.purchaseOrder);
  const {
    role,
    organization: { usesItemCustomization },
  } = useSelector((state) => state.currentUser);

  const povItems = _(purchaseOrderVariants)
    .filter((pov) => !pov.isSetupFee)
    .sortBy("sku", "isToInventory")
    .value();

  const [inventoryModalOpen, setInventoryModalOpen] = useState(null);
  const disabledInventorySkus = _(povItems)
    .filter((pov) => pov.isToInventory)
    .map((pov) => pov.sku)
    .value();

  const handleRemoveVariant = (povId) => {
    if (purchaseOrderVariants.length === 1) {
      dispatch(deletePurchaseOrder(id));
      navigate(backUrl);
    } else {
      dispatch(deletePurchaseOrderVariant(povId));
    }
  };

  let headCells = [
    { id: "image", label: "Preview" },
    { id: "sku", label: "Sku" },
    { id: "name", label: "Name" },
    { id: "variant", label: "Variant" },
    { id: "customization", label: "Customization" },
    { id: "packSize", label: "Pack Size", align: "right" },
    { id: "qty", label: "Qty", align: "right" },
    { id: "cost", label: "Est. Cost", align: "right" },
    { id: "actCost", label: "Act. Cost", align: "left" },
    { id: "total", label: "Total", align: "right" },
    { id: "actions", label: "" },
  ];

  if (status !== "draft") {
    headCells = headCells.filter((cell) => cell.id !== "actions");
  }

  if (permissions.supplier.includes(role)) {
    headCells = headCells.filter(
      (cell) => cell.id !== "actions" && cell.id !== "cost"
    );
  }

  if (!usesItemCustomization) {
    headCells = headCells.filter((cell) => cell.id !== "customization");
  }

  const totalRowsFirstCellLength =
    headCells.length - (status === "draft" ? 3 : 2);

  return (
    <>
      {inventoryModalOpen && (
        <CreateInventoryPOVModal
          itemId={povItems[0].item.id}
          handleClose={() => setInventoryModalOpen(null)}
          disabledSkus={disabledInventorySkus}
        />
      )}
      <TableContainer className={classes.tableContainer}>
        <Table className={classes.table}>
          <StandardTableHead classes={classes} headCells={headCells} />
          <TableBody>
            {povItems.map((row) => (
              <TableRow key={row.id}>
                <TableCell>
                  <div
                    tw="flex-shrink-0 w-12 relative rounded-md cursor-pointer overflow-hidden
                after:(absolute inset-0 bg-neutral-900 opacity-0 transition-opacity)
                hover:after:opacity-10"
                    role="button"
                    tabIndex={-1}
                    onClick={() =>
                      dispatch(
                        setItemPreview({
                          id: row.item.id,
                          focusImageId: row.imageId,
                        })
                      )
                    }
                  >
                    <CldImage
                      tw="w-full aspect-square object-contain 
                  border-2 border-neutral-200
                bg-white rounded-md"
                      image={getItemPreviewImage(
                        {
                          images: row.item.images.imgUrlLg,
                        },
                        row.imageId
                      )}
                      size="thumbnail"
                    />
                  </div>
                </TableCell>
                <TableCell>
                  <div>{row.warehouseId ?? row.sku}</div>
                  {row.isToInventory && (
                    <div
                      tw="inline-block px-1 py-0.5 mr-2 text-xs whitespace-nowrap font-medium tracking-wider rounded
                    bg-blue-100 text-blue-900"
                    >
                      RESTOCK
                    </div>
                  )}
                </TableCell>
                <TableCell>{row.item.name}</TableCell>
                <TableCell>{row.variantNames ?? "---"}</TableCell>
                {usesItemCustomization && (
                  <TableCell>{row.customization ?? "---"}</TableCell>
                )}
                <TableCell align="right">{row.packSize}</TableCell>
                <TableCell align="right">{row.qty}</TableCell>
                {!permissions.supplier.includes(role) && (
                  <TableCell align="right">
                    {formatMoney(row.initialCost)}
                  </TableCell>
                )}
                {status !== "complete" &&
                  !permissions.supplier.includes(role) && (
                    <TableCell padding="checkbox" tw="w-32">
                      <DebounceTextField
                        value={formatMoney(row.actualCost).split("$").join("")}
                        id={row.id}
                        setter={updatePurchaseOrderVariant}
                        attr={"actual-cost"}
                        disabled={isLoading}
                        fullWidth
                        tw="m-0"
                        isNumberOnly
                        {...moneyAdornment}
                      />
                    </TableCell>
                  )}
                {(status === "complete" ||
                  permissions.supplier.includes(role)) && (
                  <TableCell align="right">
                    {formatMoney(row.actualCost)}
                  </TableCell>
                )}
                <TableCell align="right">
                  {formatMoney(row.actualCost * row.qty)}
                </TableCell>
                {status === "draft" && (
                  <TableCell align="center" padding="checkbox">
                    <IconButton
                      onClick={(event) => {
                        event.stopPropagation();
                        handleRemoveVariant(row.id);
                      }}
                      size="small"
                      edge="start"
                    >
                      <Cancel size="small" color="inherit" />
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
            ))}
            {purchaseOrderVariants.find((pov) => pov.isSetupFee) && (
              <>
                <TableRow>
                  <TableCell colSpan={headCells.length} />
                </TableRow>
                {purchaseOrderVariants
                  .filter((pov) => pov.isSetupFee)
                  .map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>Set Up Fee:</TableCell>
                      <TableCell colSpan={3}>{row.setupFeeDesc}</TableCell>
                      <TableCell
                        colSpan={permissions.supplier.includes(role) ? 2 : 1}
                      />
                      {status !== "complete" &&
                      !permissions.supplier.includes(role) ? (
                        <TableCell padding="checkbox">
                          <DebounceTextField
                            value={formatMoney(row.actualCost)
                              .split("$")
                              .join("")}
                            id={row.id}
                            setter={updatePurchaseOrderVariant}
                            attr={"actual-cost"}
                            disabled={isLoading}
                            fullWidth
                            tw="m-0"
                            isNumberOnly
                            {...moneyAdornment}
                          />
                        </TableCell>
                      ) : (
                        <TableCell />
                      )}
                      <TableCell align="right">
                        {formatMoney(row.actualCost)}
                      </TableCell>
                      {status === "draft" && (
                        <TableCell>
                          <IconButton
                            onClick={(event) => {
                              event.stopPropagation();
                              handleRemoveVariant(row.id);
                            }}
                            size="large"
                          >
                            <Cancel color="inherit" />
                          </IconButton>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
              </>
            )}
            {!permissions.supplier.includes(role) && status !== "complete" && (
              <TableRow>
                <TableCell colSpan={headCells.length}>
                  <div tw="flex gap-4">
                    <StyledButton
                      outlined
                      onClick={() => {
                        handleSetupFeeOpen();
                      }}
                    >
                      ADD SETUP FEE
                    </StyledButton>
                    {status === "draft" && (
                      <StyledButton
                        outlined
                        onClick={() => {
                          setInventoryModalOpen(true);
                        }}
                      >
                        ADD INVENTORY
                      </StyledButton>
                    )}
                  </div>
                </TableCell>
              </TableRow>
            )}
            <PurchaseOrderVariantTableFooter
              totalRowsFirstCellLength={totalRowsFirstCellLength}
            />
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

PurchaseOrderVariantTable.propTypes = {
  handleSetupFeeOpen: PropTypes.func.isRequired,
  backLink: PropTypes.string,
};

export default PurchaseOrderVariantTable;
