/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router";

import { useQueryClient } from "@tanstack/react-query";

import {
  FixedHeightScrollLastChild,
  PageTitle,
  StyledButton,
} from "@components/StyledComponents";
import {
  BudgetBulkUpload,
  BudgetModal,
  BudgetTable,
  budgetsKeyFactory,
} from "@features/budgets";
import { usePaginatedBudgetsQuery } from "@features/budgets";
import { useFilterParams } from "@features/filters";
import { Filters } from "@features/filters";
import { RequestReportButton, useReport } from "@features/reports";
import { BudgetWithCalcs } from "@models/Budget";
import DocTitle from "@utility/DocTitle";
import permissions from "@utils/permissions";
import useRoleIs from "@utils/useRoleIs";

const BudgetAdmin = () => {
  const navigate = useNavigate();
  const roleIs = useRoleIs();
  const queryClient = useQueryClient();
  const [filterParams] = useFilterParams();

  const { budgetLocation } = useSelector(
    (state: any) => state.currentUser.organization
  );
  const params = {
    filter: {
      isActive: filterParams.isActive,
      userIds: filterParams.users,
      name: filterParams.q,
    },
    include: "budget_calculations",
  };
  useReport("budgets", ["budgets", params]);
  const { data, ...tableProps } = usePaginatedBudgetsQuery(params);

  const [selectedBudget, setSelectedBudget] = useState<BudgetWithCalcs | null>(
    null
  );
  const handleBudgetClick = (budget: BudgetWithCalcs) => {
    queryClient.setQueryData(
      budgetsKeyFactory.detail(budget.id).queryKey,
      budget
    );
    navigate(`/admin/budgets/${budget.id}`);
  };

  return (
    <>
      <DocTitle title={"Budget Admin"} />
      {selectedBudget && (
        <BudgetModal
          budget={selectedBudget}
          handleClose={() => setSelectedBudget(null)}
        />
      )}
      <FixedHeightScrollLastChild>
        <div tw="flex items-center justify-between flex-wrap gap-2">
          <PageTitle>Budgets</PageTitle>
          <div tw="flex gap-2 flex-wrap">
            <BudgetBulkUpload />
            <RequestReportButton reportName="budgets" />
            {roleIs(permissions.admin) && (
              <StyledButton cta component={Link} to={"/admin/budgets/new"}>
                New Budget
              </StyledButton>
            )}
          </div>
        </div>
        <Filters
          slots={[
            "isActive",
            budgetLocation === "user" ? "users" : "territories",
          ]}
          defaultValues={{ isActive: true }}
          searchTitle="Search Budget Name"
        />
        <BudgetTable
          {...tableProps}
          rows={data ?? []}
          query={filterParams.q}
          onRowClick={handleBudgetClick}
          onHistoryButtonClick={(row) => setSelectedBudget(row)}
        />
      </FixedHeightScrollLastChild>
    </>
  );
};

export default BudgetAdmin;
