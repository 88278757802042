/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { Link } from "react-router";

import { sortBy } from "lodash";

import { StyledButton } from "../../../components/StyledComponents";
import { OrderWindow, Program } from "../../../models";
import { formatUtcDate, utcDate } from "../../../utility/utilityFunctions";
import { isOrderWindowActive } from "../../orderWindows";
import BlockCard from "../../ui/BlockCard";

type StatusProps = {
  handleSave: () => void;
  program: Program;
};

const orderWindowLink = (ow: OrderWindow) => (
  <Link to={`/admin/order-windows/edit/${ow.id}`} target="_blank">
    {ow.name}
  </Link>
);

const Status = ({ handleSave, program }: StatusProps) => {
  const { setValue } = useFormContext();
  const status = useWatch({ name: "status" });
  const isActive = useWatch({ name: "isActive" });

  const robustStatusMessage = useMemo(() => {
    if (program.status === "draft")
      return "Program is draft and won't be visible to users until it is published.";

    if (!program.isActive)
      return "Program is inactive and won't be visible to users until it is activated.";

    if (program.orderWindows.length === 0)
      return "Program is active but has no order windows. Assign an Order Window to allow users to place orders.";

    const sortedOrderWindows = sortBy(program.orderWindows, "openDate");
    const activeOrderWindows = sortedOrderWindows.filter(isOrderWindowActive);
    if (activeOrderWindows.length > 0) {
      const latestOw = activeOrderWindows[0];
      return (
        <>
          Program is orderable until{" "}
          <b>{formatUtcDate(latestOw.closeDate, "PP")}</b> on{" "}
          <b>{orderWindowLink(latestOw)}</b>.
        </>
      );
    }

    const nextUpcomingOw = sortedOrderWindows.find(
      (ow) => utcDate(ow.openDate) > new Date()
    );
    if (nextUpcomingOw)
      return (
        <>
          Program is currently <b>not orderable</b>. The next order window opens{" "}
          <b>{formatUtcDate(nextUpcomingOw.openDate, "PP")}</b> on{" "}
          <b>{orderWindowLink(nextUpcomingOw)}</b>.
        </>
      );

    const lastOw = sortedOrderWindows
      .reverse()
      .find((ow) => utcDate(ow.closeDate) < new Date());
    if (lastOw)
      return (
        <>
          Program is currently <b>not orderable</b>. The last order window
          closed <b>{formatUtcDate(lastOw.closeDate, "PP")}</b> on{" "}
          <b>{orderWindowLink(lastOw)}</b>.
        </>
      );
    // This line should never be reached
    return "Program is not currently orderable and has no upcoming order windows.";
  }, [program]);

  const handleMarkAsComplete = () => {
    setValue("status", "complete");
    handleSave();
  };

  return (
    <BlockCard
      title="Program Status"
      titleElement={
        <h3 tw="text-neutral-600">
          {status === "complete" ? "Complete" : "Draft"}
        </h3>
      }
      tw="flex flex-col"
    >
      <div
        css={{
          "&": tw`text-lg font-light text-neutral-600`,
          b: tw`font-normal`,
          a: tw`underline`,
        }}
      >
        {robustStatusMessage}
      </div>
      {status === "complete" ? (
        <div>
          {isActive && (
            <button
              tw="mt-2 text-sm text-neutral-500 hover:text-red-600 hover:underline"
              onClick={() => setValue("isActive", false, { shouldDirty: true })}
            >
              Deactivate Program
            </button>
          )}
          {!isActive && (
            <button
              tw="mt-2 text-base text-blue-700 hover:underline"
              onClick={() => setValue("isActive", true, { shouldDirty: true })}
            >
              Activate Program
            </button>
          )}
        </div>
      ) : (
        <StyledButton tw="mt-2" fullWidth cta onClick={handleMarkAsComplete}>
          Mark as complete
        </StyledButton>
      )}
    </BlockCard>
  );
};

export default Status;
