/** @jsxImportSource @emotion/react */
import "twin.macro";

import React from "react";
import { Link } from "react-router";

import { ArrowForwardRounded } from "@mui/icons-material";
import { Button } from "@mui/material";

import { OpaqueCard, SectionTitle } from "@components/StyledComponents";
import { RequestForQuote } from "@models/RequestForQuote";
import permissions from "@utils/permissions";
import useRoleIs from "@utils/useRoleIs";

import RfqAttachments from "./RfqAttachments";

const RfqItemReference = ({ rfq }: { rfq: RequestForQuote }) => {
  const roleIs = useRoleIs();
  const specArray = Object.entries(rfq.itemSpecification ?? {});
  return (
    <OpaqueCard tw="sticky top-[var(--header-height)] p-6 space-y-6 bg-neutral-200">
      <div>
        <SectionTitle tw="text-primary-800">Item Reference</SectionTitle>
        {rfq.status === "draft" && (
          <p tw="text-neutral-600 text-sm mb-3 -mt-1 italic">
            * The following will be sent along with the quote request.
          </p>
        )}
        <h2 tw="text-neutral-800 font-medium text-lg">{rfq.itemName}</h2>

        <p tw="text-neutral-600 whitespace-pre-line mt-2">
          {rfq.itemDescription}
          {!rfq.itemDescription && (
            <i tw="text-neutral-500">No description available.</i>
          )}
        </p>
        {specArray.length > 0 && (
          <div tw="mt-3 grid grid-cols-[auto 1fr] gap-x-4 gap-y-1">
            {specArray.map(([key, value], i) => (
              <React.Fragment key={i}>
                <b tw="font-medium">{key}: </b>
                <span>{value}</span>
              </React.Fragment>
            ))}
          </div>
        )}
        {roleIs(permissions.admin) && (
          <Button
            component={Link}
            to={`/admin/items/${rfq.item.id}`}
            tw="-ml-2 mt-2 normal-case text-sm text-primary-800 hover:text-primary-900"
            endIcon={<ArrowForwardRounded />}
          >
            Item Admin
          </Button>
        )}
      </div>
      {rfq.status !== "draft" && (
        <div>
          <SectionTitle tw="text-primary-800">Attachments</SectionTitle>
          <RfqAttachments rfq={rfq} />
        </div>
      )}
    </OpaqueCard>
  );
};

export default RfqItemReference;
