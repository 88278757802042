/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { Link } from "react-router";

import { formatUtcDate } from "@utility/utilityFunctions";
import useRoleIs from "@utils/useRoleIs";

import { useConceptBoardContext } from "../ConceptBoardContext";

const BoardOverview = () => {
  const { description, conceptCategories, budget, deadline, suppliers } =
    useConceptBoardContext();

  const isSupplier = useRoleIs()("supplier");

  return (
    <div
      tw="space-y-4 text-base text-neutral-600"
      css={{
        h5: tw`mb-1 text-base font-medium text-neutral-800`,
        p: tw`max-w-prose`,
      }}
    >
      <div>
        <h5>Brief</h5>
        <p>{description}</p>
      </div>
      {!isSupplier && budget && (
        <h5>
          Budget: <span tw="font-normal">{budget}</span>
        </h5>
      )}
      {deadline && (
        <h5>
          Deadline:{" "}
          <span tw="font-normal">{formatUtcDate(deadline, "PP")}</span>
        </h5>
      )}
      {!isSupplier && suppliers.length > 0 && (
        <div>
          <h5>Suppliers</h5>
          <p>{suppliers.map(({ name }) => name).join(", ")}</p>
        </div>
      )}
      {conceptCategories.length > 0 && (
        <div>
          <h5>Item Categories of interest:</h5>
          {conceptCategories.map(({ name }) => name).join(", ")}
        </div>
      )}
      <Link
        to="edit"
        tw="mt-6 block text-primary-800 hover:text-primary-900 hover:underline"
      >
        Edit Board
      </Link>
    </div>
  );
};

export default BoardOverview;
