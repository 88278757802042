import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useQuery } from "@tanstack/react-query";

import { OrderWindow } from "@models/OrderWindow";
import client, { RequestParams } from "@services/api";

export const orderWindowsKeyFactory = createQueryKeys("order-windows", {
  detail: (id: string) => ({
    queryKey: [id],
    queryFn: () =>
      client.get<OrderWindow>(`order-windows/${id}`).then((res) => res.data),
  }),
  list: (params) => ({
    queryKey: [params],
    queryFn: () =>
      client
        .get<OrderWindow[]>("order-windows", { params })
        .then((res) => res.data),
  }),
});

export const useOrderWindowQuery = (id: string | null | undefined) => {
  return useQuery({
    ...orderWindowsKeyFactory.detail(id!),
    enabled: !!id,
    staleTime: 60_000,
  });
};

export const useOrderWindowsListQuery = (params: RequestParams) => {
  return useQuery({
    ...orderWindowsKeyFactory.list({ skipPagination: true, ...params }),
    staleTime: 60_000,
  });
};
