/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router";

import { MoreVert } from "@mui/icons-material";
import { IconButton, MenuItem } from "@mui/material";

import { useQueryClient } from "@tanstack/react-query";
import _ from "lodash";

import ReactQueryTable, {
  PaginatedResourceProps,
} from "@components/Table/ReactQueryTable";
import SearchableText from "@components/Table/SearchableText";
import OptionsMenu from "@components/Utility/OptionsMenu";
import {
  ItemIdentifiers,
  getItemPreviewImage,
  itemsKeyFactory,
  useUpdateItemMutation,
} from "@features/items";
import { Item } from "@models/Item";
import { setItemPreview } from "@redux/slices/items/itemSlice";
import { CldImage } from "@services/cloudinary";
import { upCase } from "@utility/utilityFunctions";

const ItemAdminTable = ({
  items,
  query,
  ...tableProps
}: PaginatedResourceProps & {
  items: Item[];
  query?: string;
}) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const updateItemMutation = useUpdateItemMutation();
  const usesAllocations = useSelector(
    (state: any) => !!state.currentUser.organization.allocationLocation
  );

  const handleToggleActive = (row: Item) => {
    updateItemMutation.mutate({
      id: row.id,
      isActive: !row.isActive,
      channels: row.channels,
      territories: row.territories,
    });
  };

  return (
    <ReactQueryTable
      rows={items}
      onRowClick={(item) =>
        queryClient.setQueryData<Item>(
          itemsKeyFactory.detail(item.id).queryKey,
          item
        )
      }
      columns={[
        {
          id: "_preview",
          label: "Preview",
          sort: "id",
          render: (_, row) => (
            <div
              tw="flex-shrink-0 w-20 relative rounded-md cursor-pointer overflow-hidden
                after:(absolute inset-0 bg-neutral-900 opacity-0 transition-opacity)
                hover:after:opacity-20"
              role="button"
              tabIndex={-1}
              onClick={(e) => {
                e.stopPropagation();
                dispatch(setItemPreview({ id: row.id }));
              }}
            >
              <CldImage
                tw="w-full aspect-square object-contain 
                  border-2 border-neutral-200
                bg-white rounded-md"
                image={getItemPreviewImage(row)}
                size="thumbnail"
              />
            </div>
          ),
        },
        {
          id: "_item",
          label: "Item",
          sort: "name",
          to: (row) => `/admin/items/${row.id}`,
          render: (_, row) => (
            <>
              <ItemIdentifiers item={row} query={query} />
              <div
                tw="inline-block text-base text-neutral-800 leading-tight mt-2 
                    hover:(underline text-neutral-900) underline-offset-2"
              >
                <SearchableText text={row.name} query={query} />
              </div>
            </>
          ),
        },
        {
          id: "description",
          label: "Description",
          to: (row) => `/admin/items/${row.id}`,
          render: (d) => (
            <SearchableText text={d} query={query} maxLength={200} />
          ),
        },
        {
          id: "orderType",
          label: "Order Type",
          render: (orderType) => orderType && upCase(orderType, "-"),
        },
        {
          id: "status",
          label: "Status",
          sort: "status",
          render: (status, p) =>
            !p.isActive
              ? "Inactive"
              : status === "complete"
                ? "Active"
                : "Draft",
        },
        {
          id: "_action",
          label: "",
          render: (_, row) => (
            <OptionsMenu
              title="Actions"
              component={(props) => (
                <IconButton
                  {...props}
                  tw="-my-2"
                  onClick={(e) => {
                    e.stopPropagation();
                    props.onClick(e);
                  }}
                >
                  <MoreVert />
                </IconButton>
              )}
            >
              {usesAllocations && (
                <MenuItem
                  component={Link}
                  to={`/allocations/${row.id}`}
                  target="_blank"
                >
                  Manage Allocations
                </MenuItem>
              )}
              <MenuItem
                onClick={() => {
                  navigate(`/admin/items/${row.id}`);
                }}
              >
                Edit Item
              </MenuItem>
              {row.status !== "draft" && (
                <MenuItem onClick={() => handleToggleActive(row)}>
                  {row.isActive ? "Deactivate Item" : "Activate Item"}
                </MenuItem>
              )}
            </OptionsMenu>
          ),
        },
      ]}
      {...tableProps}
    />
  );
};

export default ItemAdminTable;
