/** @jsxImportSource @emotion/react */
import "twin.macro";
import tw from "twin.macro";

import { useState } from "react";
import { useSelector } from "react-redux";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import {
  useCreateFavoriteAddressesMutation,
  useFavoriteAddressesListQuery,
} from "@features/addresses/";

import { Contained, OpaqueCard, StyledButton } from "../../StyledComponents";
import FavoriteAddressList from "./FavoriteAddressList";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const FavoriteAddresses = () => {
  const classes = useStyles();
  const [currentList, setCurrentList] = useState(false);

  const currentTerritoryId = useSelector(
    (state) => state.currentUser.currentTerritoryId
  );

  const createFavoriteAddressesSet = useCreateFavoriteAddressesMutation();

  const { data, isLoading, isFetching } = useFavoriteAddressesListQuery({
    filter: {
      territoryIds: [currentTerritoryId],
    },
  });

  const faveAddressLists = data ? data.sort((a, b) => a.id - b.id) : [];

  const handleExpand = (id) => (evt, isExpanded) => {
    setCurrentList(isExpanded ? id : false);
  };

  const handleCreate = () => {
    createFavoriteAddressesSet.mutate({
      name: `New List ${currentTerritoryId} - ${faveAddressLists?.length + 1}`,
      territoryId: currentTerritoryId,
      addresses: [],
    });
  };

  return (
    <Contained>
      <OpaqueCard tw="p-0" css={{ ".Mui-expanded": tw`my-0!` }}>
        {faveAddressLists &&
          faveAddressLists.length > 0 &&
          faveAddressLists.map((addressList) => (
            <Accordion
              key={addressList.id}
              expanded={currentList === addressList.id}
              onChange={handleExpand(addressList.id)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={addressList.name}
                id={`${addressList.name}-${addressList.id}`}
              >
                <Typography className={classes.headerText}>
                  {addressList.name}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FavoriteAddressList addressList={addressList} />
              </AccordionDetails>
            </Accordion>
          ))}
      </OpaqueCard>
      <div tw="space-y-4 mt-4 relative">
        {!isFetching && faveAddressLists.length === 0 && (
          <Typography className={classes.headerText}>
            You currently do not have any Favorite Address Lists....
          </Typography>
        )}
        <StyledButton cta onClick={handleCreate} disabled={isLoading}>
          ADD NEW LIST
        </StyledButton>
        <div tw="absolute">
          {isFetching && <CircularProgress color="primary" />}
        </div>
      </div>
    </Contained>
  );
};

export default FavoriteAddresses;
