/** @jsxImportSource @emotion/react */
import "twin.macro";

import { Link, useParams } from "react-router";

import { Skeleton } from "@mui/material";

import { Contained, StyledButton } from "@components/StyledComponents";
import { Title } from "@features/ui";
import { useUserQuery } from "@features/users";
import { UserForm } from "@features/users";
import DocTitle from "@utility/DocTitle";

import ErrorPage from "./ErrorPage";

const UserSkeleton = () => {
  return (
    <div tw="grid gap-x-4 gap-y-6">
      <Skeleton variant="rounded" height={200} tw="rounded-xl" />
      <Skeleton variant="rounded" height={300} tw="rounded-xl" />
    </div>
  );
};

const User = () => {
  const { id } = useParams();
  const isNew = !id || id === "new";
  const { data: user, error } = useUserQuery(isNew ? null : id);

  const isLoading = !isNew && !user;

  if (error) return <ErrorPage error={error} />;

  return (
    <Contained tw="pb-6 max-w-screen-md mx-auto">
      <DocTitle title={isNew ? "New User" : user?.name || "User"} />
      <div tw="flex justify-between items-center mb-6">
        <Title backUrl="/admin/users">
          {isNew ? "New User" : user?.name || <Skeleton tw="w-48" />}
        </Title>
        {!isNew && (
          <StyledButton cta component={Link} to="/admin/users/new">
            New User
          </StyledButton>
        )}
      </div>
      {isLoading && <UserSkeleton />}

      {!isLoading && <UserForm fullPage user={user} id={isNew ? null : id} />}
    </Contained>
  );
};

export default User;
