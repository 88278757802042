/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useDispatch, useSelector } from "react-redux";

import { FavoriteBorderRounded, FavoriteRounded } from "@mui/icons-material";
import { Checkbox, FormControlLabel, Skeleton, Tooltip } from "@mui/material";

import { xor } from "lodash";

import { OpaqueCard } from "@components/StyledComponents";
import ReactQueryTable, { Column } from "@components/Table/ReactQueryTable";
import SearchableText from "@components/Table/SearchableText";
import { useGroupCategoriesListQuery } from "@features/groupCategories";
import { ItemPreviewImageButton } from "@features/items";
import { Group, Item } from "@models";
import { setItemSelection } from "@redux/slices/items/itemSlice";
import { toggleFavoriteItem } from "@redux/slices/user/currentUserSlice";
import { formatMoneyString, upCase } from "@utility/utilityFunctions";

import { ItemCatalogProps } from "./ItemCatalogView";
import ItemIdentifiers from "./ItemIdentifiers";
import ItemOptionsCount from "./ItemOptionsCount";

const insertIf = (cond, val) => (cond ? [val] : []);

const ItemCatalogTable = ({
  rows,
  query,
  showCheckbox,
  isRowDisabled,
  getCheckboxText,
  rowActions,
  ...tableProps
}: ItemCatalogProps) => {
  const dispatch = useDispatch();
  const { selectedItems } = useSelector((state: any) => state.items);
  const { favoriteItemIds } = useSelector((state: any) => state.currentUser);
  const { data: groupCategories, isLoading: categoriesLoading } =
    useGroupCategoriesListQuery();

  const toggleSelection = (id) =>
    dispatch(setItemSelection({ items: xor(selectedItems, [id]) }));

  const onClickAll = () => {
    if (selectedItems.length === rows.length) {
      dispatch(setItemSelection({ items: [] }));
    } else {
      dispatch(setItemSelection({ items: rows.map((row) => row.id) }));
    }
  };

  const columns: Column<Item>[] = [
    ...insertIf(showCheckbox, {
      id: "checkbox",
      label: (
        <Checkbox
          tw="-m-4 -ml-2"
          indeterminate={
            selectedItems.length > 0 && selectedItems.length < rows.length
          }
          checked={rows.length > 0 && selectedItems.length === rows.length}
          onChange={onClickAll}
          inputProps={{ "aria-label": "select all items" }}
          disabled={rows.length === 0}
        />
      ),
      render: (_, row) => (
        <FormControlLabel
          tw="-m-4 -ml-2"
          control={
            <Checkbox
              checked={selectedItems.includes(row.id)}
              onClick={(e) => e.stopPropagation()}
              onChange={() => toggleSelection(row.id)}
              disabled={isRowDisabled?.(row) ?? false}
            />
          }
          label={getCheckboxText?.(row) ?? ""}
          labelPlacement="bottom"
        />
      ),
    }),
    {
      id: "preview",
      label: "Preview",
      render: (_, row) => <ItemPreviewImageButton item={row} />,
    },

    {
      id: "item",
      label: "Item",
      sort: "name",
      render: (_, row) => (
        <div>
          <div tw="flex gap-2 items-center text-primary-600">
            <Tooltip title="Favorite Item">
              <Checkbox
                className="group"
                tw="-m-2"
                edge="start"
                checked={favoriteItemIds.includes(+row.id)}
                size="small"
                icon={
                  <FavoriteBorderRounded tw="text-lg text-neutral-400 group-hover:text-neutral-700" />
                }
                checkedIcon={<FavoriteRounded tw="text-lg text-primary-600" />}
                onClick={(e) => e.stopPropagation()}
                onChange={() => dispatch(toggleFavoriteItem(row.id))}
              />
            </Tooltip>
            <ItemIdentifiers item={row} query={query} />
          </div>
          <div tw="text-base! text-neutral-800 leading-tight mt-2">
            <SearchableText text={row.name} query={query} />
          </div>
          {row.variants.length > 1 && <ItemOptionsCount item={row} />}
        </div>
      ),
    },
    {
      id: "description",
      label: "Description",
      render: (t) => (
        <SearchableText text={t ?? "---"} query={query} maxLength={200} />
      ),
    },
    {
      id: "groups",
      label: "Categories",
      render: (groups: Group[]) =>
        categoriesLoading ? (
          <Skeleton />
        ) : (
          <>
            {groupCategories?.map((_cat, i) => {
              const cat = groups.find(
                (group) => group.groupCategory.id === _cat.id
              );
              return (
                cat && (
                  <Tooltip
                    key={i}
                    title={cat?.groupCategory.name ?? "Category"}
                  >
                    <div tw="text-sm text-gray-500 hover:text-gray-900">
                      {cat?.name ?? "---"}
                    </div>
                  </Tooltip>
                )
              );
            })}
          </>
        ),
    },
    {
      id: "packSize",
      label: "Pack Size",
      render: (packSize) =>
        packSize === 1 ? (
          <span>Each</span>
        ) : (
          <>
            <span>{packSize}</span>
            <span tw="text-gray-500"> / pack</span>
          </>
        ),
    },
    {
      id: "orderType",
      label: "Order Type",
      render: (orderType) => upCase(orderType),
    },
    {
      id: "price",
      label: "Price",
      render: (price) => (
        <span tw="text-base text-neutral-900">{formatMoneyString(price)}</span>
      ),
    },
    ...insertIf(rowActions, {
      id: "actions",
      label: "Actions",
      render: (_, row) => rowActions?.(row),
    }),
  ];

  return (
    <OpaqueCard tw="p-0 overflow-hidden relative">
      <ReactQueryTable {...tableProps} rows={rows} columns={columns} />
    </OpaqueCard>
  );
};

export default ItemCatalogTable;
