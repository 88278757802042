/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useState } from "react";
import { useSelector } from "react-redux";

import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import ChannelModal from "@components/Settings/Channels/ChannelModal";
import ChannelTable from "@components/Settings/Channels/ChannelTable";
import {
  FixedHeightScrollLastChild,
  OpaqueCard,
  StyledButton,
} from "@components/StyledComponents";
import DocTitle from "@utility/DocTitle";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const AdminChannels = () => {
  const classes = useStyles();

  const [modal, setModal] = useState({
    open: false,
    type: null,
    id: null,
  });

  const { channels, isLoading } = useSelector((state) => state.channels);
  const {
    organization: { usesChannels },
  } = useSelector((state) => state.currentUser);

  const handleModalClose = () => {
    setModal({
      open: false,
      type: null,
      id: null,
    });
  };

  const handleModalOpen = (id, type) => {
    setModal({
      open: true,
      type: type,
      id: id,
    });
  };

  return (
    <>
      <DocTitle title={"Admin Channels"} />
      <FixedHeightScrollLastChild>
        {modal.open && (
          <ChannelModal
            open={modal.open}
            handleClose={handleModalClose}
            type={modal.type}
            id={modal.id}
          />
        )}
        <div className={classes.titleBar}>
          <Typography className={classes.titleText}>Edit Channels</Typography>
          <div className={classes.innerConfigDiv}>
            <StyledButton
              cta
              onClick={() => handleModalOpen(null, "new")}
              disabled={!usesChannels}
            >
              NEW CHANNEL
            </StyledButton>
          </div>
        </div>
        <br />
        <Typography className={classes.bodyText} color="textSecondary">
          <em>
            Channels are used as a way to limit availability on items based on
            channel assignment, and help when determining budgets when creating
            orders. They are assigned to users, items, and programs. When
            creating an order, it will assigned to the channel the user is
            currently working in.
          </em>
        </Typography>
        <br />
        {usesChannels && (
          <OpaqueCard tw="p-0 overflow-hidden">
            <ChannelTable
              handleChannelClick={handleModalOpen}
              channels={channels}
              isLoading={isLoading}
            />
          </OpaqueCard>
        )}
        {!usesChannels && (
          <div className={classes.center}>
            <br />
            <br />
            <Typography className={classes.headerText}>
              Your organization currently does not use channels, if this was not
              intended please contact help@selectbrandhub.com.
            </Typography>
          </div>
        )}
      </FixedHeightScrollLastChild>
    </>
  );
};

export default AdminChannels;
