/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";

import { CircularProgress } from "@mui/material";

import { zodResolver } from "@hookform/resolvers/zod";

import { Contained, RecessCard } from "@components/StyledComponents";
import useConfirm from "@features/confirm";
import { ResourceError } from "@features/errors";
import { ItemModal } from "@features/items";
import { SaveFormBanner, Title } from "@features/ui";
import { useMutateError } from "@services/api";
import DocTitle from "@utility/DocTitle";
import { utcToLocalDate } from "@utility/utilityFunctions";
import { usePersistFormChanges } from "@utils/forms";

import General from "./adminPageBlocks/General";
import Goals from "./adminPageBlocks/Goals";
import Image from "./adminPageBlocks/Image";
import Items from "./adminPageBlocks/Items";
import OrderWindows from "./adminPageBlocks/OrderWindows";
import OrderingInstructions from "./adminPageBlocks/OrderingInstructions";
import ItemAssignment from "./adminPageBlocks/ProgramItemAssignment";
import Quotes from "./adminPageBlocks/Quotes";
import Status from "./adminPageBlocks/Status";
import Strategies from "./adminPageBlocks/Strategies";
import Visibility from "./adminPageBlocks/Visibility";
import {
  useCreateProgramMutation,
  useProgramQuery,
  useUpdateProgramMutation,
} from "./data";
import { defaultProgramValues, formDataFromProgram } from "./maps";
import { programSchema } from "./programSchema";

const TwoColLayout = tw(Contained)`
  grid gap-4 pb-6
  lg:(
    [grid-template-columns:2fr 1fr]
    max-w-screen-xl
    mx-auto
  )
`;
const Col = tw.div`overflow-hidden min-w-0 contents lg:(block space-y-4)`;

const ProgramCreate = () => {
  const { id, view } = useParams();
  const navigate = useNavigate();
  const confirm = useConfirm();
  const setError = useMutateError();

  const { usesRfqs } = useSelector(
    (state: any) => state.currentUser.organization
  );

  const updateProgramMutation = useUpdateProgramMutation();
  const createProgramMutation = useCreateProgramMutation();

  const { data, error } = useProgramQuery(id);

  const formAttrs = useForm({
    defaultValues: defaultProgramValues,
    resolver: zodResolver(programSchema),
  });

  const {
    reset,
    watch,
    handleSubmit,
    setValue,
    formState: { isDirty },
  } = formAttrs;

  const hasInitialized = Boolean(watch("id"));

  const saveValues = async (value: Record<string, any>) => {
    // Only updates provided values, not other fields
    if (!data) return;
    const values = {
      id: data.id,
      orderWindows: data.orderWindows,
      itemIds: data.itemIds ?? [],
      channelId: data.channel?.id ?? null,
      territories: data.territories,
    };
    return updateProgramMutation
      .mutateAsync({ ...values, ...value })
      .catch(setError);
  };

  const saveItems = (itemIds: string[]) => {
    return saveValues({ itemIds });
  };

  const saveOrderWindows = async (orderWindowIds: string[]) => {
    const orderWindows = orderWindowIds.map((id) => ({
      id,
      type: "order-window",
    }));
    return saveValues({ orderWindows });
  };

  const onValidateSuccess = (formData: typeof defaultProgramValues) => {
    if (!data) return;
    updateProgramMutation.mutate(
      {
        ...formData,
        goals: formData.goals.filter((g) => g.value).map((g) => g.value),
        orderWindows: data?.orderWindows,
        itemIds: data.itemIds ?? [],
        strategies: formData.strategies
          .filter((g) => g.value)
          .map((g) => g.value),
      },
      {
        onSuccess: (program) => {
          reset(formDataFromProgram(program));
        },
        onError: (err) => {
          setError(err);
          onValidateFail();
        },
      }
    );
  };

  const onValidateFail = () => {
    if (!data) return;
    // Reset  to original saved value
    setValue("status", data.status ?? "draft");
    setValue("channelId", data.channel?.id ?? "");
  };

  const handleSave = handleSubmit(onValidateSuccess, onValidateFail);

  const handleChangeChannel = async () => {
    if (!data) return;
    let confirmChange = true;
    if (data.itemIds && data.itemIds.length > 0) {
      confirmChange = await confirm(
        "Some items or order windows may not be valid for the new channel.",
        {
          title: "Are you sure you want to change the channel? ",
        }
      );
    }
    if (confirmChange) {
      handleSave();
    } else {
      setValue("channelId", data.channel?.id ?? "");
    }
  };

  const handleReset = () => {
    if (!data) return;
    reset(formDataFromProgram(data));
  };

  const handleCopyProgram = async () => {
    if (!data) return;
    const { id: __, channel, ...programCopy } = data;
    createProgramMutation.mutate(
      {
        ...programCopy,
        channelId: channel?.id ?? null,
        name: `${data.name} (copy)`,
      },
      {
        onSuccess: (program) => {
          navigate(`/admin/programs/${program.id}`);
        },
        onError: (err) => setError(err, "Program Create"),
      }
    );
  };

  useEffect(() => {
    reset(defaultProgramValues);
  }, [id, reset]);

  useEffect(() => {
    if (data && !hasInitialized) {
      reset(formDataFromProgram(data));
    }
  }, [data, hasInitialized, reset]);

  usePersistFormChanges(
    hasInitialized && `program-admin-${id}`,
    formAttrs,
    data?.updatedAt ? utcToLocalDate(data.updatedAt) : null
  );

  if (error) return <ResourceError error={error} />;

  if (!data || !hasInitialized) {
    return (
      <RecessCard>
        <CircularProgress />
      </RecessCard>
    );
  }
  return (
    <FormProvider {...formAttrs}>
      <DocTitle title={`${data.name} | Program Admin`} />
      <ItemModal />
      {isDirty && (
        <SaveFormBanner handleSave={handleSave} handleReset={handleReset} />
      )}
      <Contained tw="lg:col-span-2 flex flex-wrap justify-between items-center gap-4 mb-4">
        <Title
          backUrl={
            view === "items" ? `/admin/programs/${data.id}` : `/admin/programs`
          }
        >
          {data.name}
        </Title>
        <button
          disabled={createProgramMutation.isPending}
          onClick={handleCopyProgram}
        >
          Copy Program
        </button>
      </Contained>
      {!view && (
        <TwoColLayout>
          <Col>
            <General />
            <Items
              saveItems={saveItems}
              numberOfItems={data.itemIds?.length ?? 0}
            />
            <OrderingInstructions />
            <Goals />
            <Strategies />
          </Col>
          <Col>
            <div tw="-order-1">
              <Status program={data} handleSave={handleSave} />
            </div>
            <Image />
            <Visibility handleChangeChannel={handleChangeChannel} />
            <OrderWindows
              program={data}
              handleSaveOrderWindows={saveOrderWindows}
            />
            {usesRfqs && <Quotes />}
          </Col>
        </TwoColLayout>
      )}

      {view === "items" && (
        <ItemAssignment
          savedItemIds={(data.itemIds ?? []).map(String)}
          saveItemsOnly={saveItems}
        />
      )}
    </FormProvider>
  );
};

export default ProgramCreate;
