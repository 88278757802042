/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { useState } from "react";
import { useSelector } from "react-redux";

import { Alert, IconButton, Skeleton } from "@mui/material";

import { format } from "date-fns";

import { OpaqueCard } from "@components/StyledComponents";
import { useBudgetQuery } from "@features/budgets";
import { useFilterParams } from "@features/filters";
import { OrderStatus } from "@features/orderHistory";
import { FaIcon } from "@features/ui";
import { utcToLocalDate } from "@utility/utilityFunctions";
import useRoleIs from "@utils/useRoleIs";

import checkoutTypeLabel from "../../checkoutTypeLabel";
import orderTypeLabel from "../../orderTypeLabel";
import { useCurrentOrderSet } from "../CurrentOrderSetContext";
import BudgetSelectModal from "../orderSetBudget/BudgetSelectModal";
import OrderSetCostCenterSelector from "../orderSetCheckout/OrderSetCostCenterSelector";
import useCanEditOrderSet from "../useCanEditOrderSet";

const BudgetInfo = () => {
  const {
    orderSet: { budgetId },
  } = useCurrentOrderSet();
  const [budgetModalOpen, setBudgetModalOpen] = useState(false);
  const { data: budget } = useBudgetQuery(budgetId);
  const roleIs = useRoleIs();
  const canEdit = useCanEditOrderSet() || roleIs("super");

  if (!budget) return <Skeleton />;
  return (
    <span>
      <span>{budget.name}</span>
      {canEdit && (
        <>
          <IconButton
            tw="ml-2"
            size="small"
            onClick={() => setBudgetModalOpen(true)}
          >
            <FaIcon icon="pen" tw="text-sm text-primary-600" />
          </IconButton>
          {budgetModalOpen && (
            <BudgetSelectModal
              open
              handleClose={() => setBudgetModalOpen(false)}
            />
          )}
        </>
      )}
    </span>
  );
};

const CostCenterInfo = () => {
  const {
    orderSet: { costCenter },
  } = useCurrentOrderSet();
  const [editing, setEditing] = useState(false);

  const roleIs = useRoleIs();
  const canEdit = useCanEditOrderSet() || roleIs("super");

  if (editing)
    return (
      <div tw="flex gap-2 items-center">
        <OrderSetCostCenterSelector
          hideLabel
          onChangeSuccess={() => setEditing(false)}
        />

        <IconButton size="small" onClick={() => setEditing(false)}>
          <FaIcon icon="close" tw="text-sm text-neutral-600" />
        </IconButton>
      </div>
    );

  return (
    <span>
      {costCenter?.name ?? "Non-selected"}
      {canEdit && (
        <IconButton tw="ml-2" size="small" onClick={() => setEditing(true)}>
          <FaIcon icon="pen" tw="text-sm text-primary-600" />
        </IconButton>
      )}
    </span>
  );
};

const LabelValuePair = ({ label, value }) => (
  <div tw="text-neutral-900">
    <div tw="text-neutral-700 text-sm tracking-wide">{label}</div>
    <div css={[tw`text-base`, !value && tw`opacity-40`]}>{value || "N/A"}</div>
  </div>
);

const OrderSetSubmittedInfo = () => {
  const { usesCostCenters } = useSelector(
    (state: any) => state.currentUser.organization
  );

  const {
    orderSet: {
      checkoutType,
      type,
      programName,
      orderWindowName,
      user,
      submittedAt,
      status,
    },
  } = useCurrentOrderSet();

  const [{ action }] = useFilterParams();

  const [submittedBanner, setSubmittedBanner] = useState(action === "submit");

  // const totalTax = _.sumBy(orders, "totalEstimatedTax");
  // const totalFreight = _.sumBy(orders, "totalEstimatedShippingCost");

  // const getTotalTax = () => {
  //   if (checkoutType === "stripe" && !includeTaxesInStripeCharge) {
  //     return 0;
  //   }
  //   if (budgetId && !includeTaxesInBudgets) {
  //     return 0;
  //   }
  //   return totalTax;
  // };

  // const getTotalFreight = () => {
  //   if (
  //     (checkoutType === "stripe" && !includeShippingInStripeCharge) ||
  //     (budgetId && !includeShippingInBudgets)
  //   ) {
  //     return 0;
  //   }
  //   return totalFreight;
  // };

  // const chargedTotal =
  //   +totalPrice + getTotalFreight() + getTotalTax() - +totalPromotionDiscount;
  return (
    <OpaqueCard tw="mb-6">
      {submittedBanner && (
        <Alert
          severity="success"
          tw="mb-4 -mt-2 -mx-2"
          onClose={() => setSubmittedBanner(false)}
        >
          <h2 tw="text-lg">Order Set Submitted!</h2>
          <p tw="text-green-900">
            {type === "pre-order"
              ? "Your orders have been submitted and will be included in the pre-order."
              : status !== "approved"
                ? "Your orders have been submitted and are now awaiting approval."
                : "Your orders have been approved."}
          </p>
        </Alert>
      )}
      <div tw="grid [grid-template-columns: repeat(auto-fill, minmax(10em, 1fr))] gap-x-4 gap-y-2 ">
        <LabelValuePair label="Order Type" value={orderTypeLabel(type)} />
        <LabelValuePair
          label="Status"
          value={<OrderStatus status={status} />}
        />

        <LabelValuePair
          label="Checkout Type"
          value={checkoutTypeLabel(checkoutType!)}
        />
        {checkoutType === "budget" && (
          <LabelValuePair label="Budget" value={<BudgetInfo />} />
        )}

        {usesCostCenters && (
          <LabelValuePair label="Cost Center" value={<CostCenterInfo />} />
        )}

        <LabelValuePair label="Orderer" value={user.name} />
        <LabelValuePair
          label="Submitted At"
          value={format(utcToLocalDate(submittedAt), "PP p")}
        />

        {type === "pre-order" && (
          <>
            <LabelValuePair label="Program" value={programName} />
            <LabelValuePair label="Order Window" value={orderWindowName} />
          </>
        )}
      </div>
    </OpaqueCard>
  );
};

export default OrderSetSubmittedInfo;
