/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Divider, List, ListItem, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { updateCurrentUser } from "@redux/slices/user/currentUserSlice";
import { SelectInput } from "@utils/forms";

import { Contained, OpaqueCard } from "../../StyledComponents";
import OrderPatchLoading from "../../Utility/OrderPatchLoading";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  defalutTerritorySelect: {
    width: "200px",
  },
}));

const blankOption = {
  id: "",
  name: "-- No Default",
};

const General = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const currentUser = useSelector((state) => state.currentUser);

  const handleChange = (e) => {
    setLoading(true);
    dispatch(
      updateCurrentUser(
        {
          [e.target.name]:
            e.target.value !== "" ? Number(e.target.value) : null,
        },
        () => setLoading(false)
      )
    );
  };

  return (
    <Contained tw="space-y-6">
      <OpaqueCard>
        <Typography className={classes.titleText}>
          Profile Information
        </Typography>
        <List classes={{ root: classes.root }}>
          <ListItem disableGutters>
            <Typography className={classes.bodyText}>
              Name: {currentUser.name}
            </Typography>
          </ListItem>
          <Divider />
          <ListItem disableGutters>
            <Typography className={classes.bodyText}>
              Email: {currentUser.email}
            </Typography>
          </ListItem>
          <Divider />
          <ListItem disableGutters>
            <Typography className={classes.bodyText}>
              Role:{" "}
              {currentUser.role[0].toUpperCase() + currentUser.role.slice(1)}
            </Typography>
          </ListItem>
          <Divider />
          <ListItem
            disableGutters
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Typography className={classes.bodyText}>
              Assigned Territories:
            </Typography>
            <br />
            <Typography className={classes.bodyText}>
              {currentUser.territories.map((terr) => terr.name).join(", ")}
            </Typography>
          </ListItem>
          <Divider />
          <ListItem
            disableGutters
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Typography className={classes.bodyText}>
              Assigned Categories:
            </Typography>
            <br />
            {currentUser.groups.length > 0 ? (
              <Typography className={classes.bodyText}>
                {[...currentUser.groups]
                  .sort((a, b) => {
                    return a.groupCategoryName < b.groupCategoryName
                      ? -1
                      : a.groupCategoryName > b.groupCategoryName
                        ? 1
                        : 0;
                  })
                  .map((g) => `${g.groupCategoryName} - ${g.name}`)
                  .join(", ")}
              </Typography>
            ) : (
              <Typography className={classes.bodyText}>
                You are not currently assigned to any categories
              </Typography>
            )}
          </ListItem>
        </List>

        <OrderPatchLoading />
      </OpaqueCard>
      {currentUser.organization.availableOrgs.length === 1 &&
        (currentUser.territories.length > 0 ||
          currentUser.channels.length > 0) && (
          <OpaqueCard>
            <Typography className={classes.headerText}>
              Profile Defaults
            </Typography>
            <Typography className={classes.bodyText} color="textSecondary">
              You will be automatically set to these defaults when logging in.
            </Typography>
            <br />
            <div tw="grid lg:grid-cols-2 gap-4">
              {currentUser.territories.length > 0 && (
                <SelectInput
                  label="Default Territory"
                  name="default-territory-id"
                  onChange={handleChange}
                  value={currentUser.defaultTerritoryId || ""}
                  options={[blankOption, ...currentUser.territories]}
                  disabled={loading}
                />
              )}

              {currentUser.channels.length > 0 && (
                <SelectInput
                  label="Default Channel"
                  name="default-channel-id"
                  onChange={handleChange}
                  value={currentUser.defaultChannelId || ""}
                  options={[blankOption, ...currentUser.channels]}
                  disabled={loading}
                />
              )}
            </div>
          </OpaqueCard>
        )}
    </Contained>
  );
};

export default General;
