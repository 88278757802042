/** @jsxImportSource @emotion/react */
import "twin.macro";

import React, { forwardRef } from "react";

import {
  ButtonProps,
  ButtonTypeMap,
  CircularProgress,
  Button as MuiButton,
} from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";

type ButtonOptions = {
  loading?: boolean | null | undefined;
};

const LoadingButton = forwardRef(function <C extends React.ElementType>(
  { loading, ...props }: ButtonProps<C, ButtonOptions>,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  return (
    <MuiButton
      ref={ref}
      {...props}
      {...(loading && {
        endIcon: <CircularProgress size={16} tw="text-inherit" />,
        disabled: true,
      })}
    />
  );
}) as OverridableComponent<ButtonTypeMap<ButtonOptions>>;

export default LoadingButton;
