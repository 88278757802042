/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { Cancel } from "@mui/icons-material";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import _ from "lodash";

import { getItemPreviewImage } from "@features/items";
import { CreateInventoryPOVModal } from "@features/purchaseOrders";
import { setItemPreview } from "@redux/slices/items/itemSlice";
import {
  deletePurchaseOrder,
  deletePurchaseOrderVariant,
} from "@redux/slices/purchaseOrders/purchaseOrderSlice";
import { CldImage } from "@services/cloudinary";
import { useLastLocation } from "@services/reactRouterDom";
import { formatMoney } from "@utility/utilityFunctions";
import permissions from "@utils/permissions";

import StandardTableHead from "../Utility/StandardTableHead";
import PurchaseOrderVariantTableFooter from "./PurchaseOrderVariantTableFooter";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const PurchaseOrderVariantOverviewTable = ({ setTableView }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const lastLocation = useLastLocation();
  const backUrl = lastLocation
    ? lastLocation.pathname + lastLocation.search
    : "/purchasing/purchase-order-rollup";

  const {
    currentPurchaseOrder: { purchaseOrderVariants, status, id },
  } = useSelector((state) => state.purchaseOrder);

  const {
    role,
    organization: { usesItemCustomization },
  } = useSelector((state) => state.currentUser);

  const povItems = _(purchaseOrderVariants)
    .filter((pov) => !pov.isSetupFee)
    .sortBy("sku", "isToInventory")
    .value();

  // Group PO variants by warehouseId or brandhub generated sku
  const totalQtyByRelevantId = povItems.reduce((acc, { qty, ...itemInfo }) => {
    const groupingID = itemInfo.warehouseId ?? itemInfo.item.sku;
    if (!acc[groupingID]) {
      acc[groupingID] = { ...itemInfo, qty: 0 };
    }
    acc[groupingID].qty += qty;
    return acc;
  }, {});

  const relevantIdVariants = Object.entries(totalQtyByRelevantId).map(
    ([value, item]) => ({
      relevantId: value,
      ...item,
    })
  );

  const [inventoryModalOpen, setInventoryModalOpen] = useState(null);
  const disabledInventorySkus = _(povItems)
    .filter((pov) => pov.isToInventory)
    .map((pov) => pov.sku)
    .value();

  const handleRemoveVariant = (povId) => {
    if (purchaseOrderVariants.length === 1) {
      dispatch(deletePurchaseOrder(id));
      navigate(backUrl);
    } else {
      dispatch(deletePurchaseOrderVariant(povId));
    }
  };

  let headCells = [
    { id: "image", label: "Preview" },
    { id: "sku", label: "Sku" },
    { id: "name", label: "Name" },
    { id: "variant", label: "Variant" },
    { id: "customization", label: "Customization" },
    { id: "packSize", label: "Pack Size", align: "right" },
    { id: "qty", label: "Total Qty", align: "right" },
    { id: "cost", label: "Est. Cost", align: "right" },
    { id: "actCost", label: "Act. Cost", align: "right" },
    { id: "total", label: "Total", align: "right" },
  ];

  if (status !== "draft") {
    headCells = headCells.filter((cell) => cell.id !== "actions");
  }

  if (role === permissions.supplier) {
    headCells = headCells.filter(
      (cell) => cell.id !== "actions" && cell.id !== "cost"
    );
  }

  if (!usesItemCustomization) {
    headCells = headCells.filter((cell) => cell.id !== "customization");
  }

  const totalRowsFirstCellLength = headCells.length - 2;

  return (
    <>
      {inventoryModalOpen && (
        <CreateInventoryPOVModal
          itemId={povItems[0].item.id}
          handleClose={() => setInventoryModalOpen(null)}
          disabledSkus={disabledInventorySkus}
        />
      )}
      <TableContainer className={classes.tableContainer}>
        <Table className={classes.table}>
          <StandardTableHead classes={classes} headCells={headCells} />
          <TableBody>
            {relevantIdVariants.map((row) => {
              const povItemsWithRelevantId = povItems
                .filter((item) => {
                  if (item.warehouseId === null) {
                    return item.item.sku === row.relevantId;
                  }
                  return item.warehouseId === row.relevantId;
                })
                .map((item) => item.id);
              const uniqueOnes = _.uniq(povItemsWithRelevantId);
              return (
                <TableRow key={row.id}>
                  <TableCell>
                    <div
                      tw="flex-shrink-0 w-12 relative rounded-md cursor-pointer overflow-hidden
                after:(absolute inset-0 bg-neutral-900 opacity-0 transition-opacity)
                hover:after:opacity-10"
                      role="button"
                      tabIndex={-1}
                      onClick={() =>
                        dispatch(
                          setItemPreview({
                            id: row.item.id,
                            focusImageId: row.imageId,
                          })
                        )
                      }
                    >
                      <CldImage
                        tw="w-full aspect-square object-contain 
                  border-2 border-neutral-200
                bg-white rounded-md"
                        image={getItemPreviewImage(
                          {
                            images: row.item.images.imgUrlLg,
                          },
                          row.imageId
                        )}
                        size="thumbnail"
                      />
                    </div>
                  </TableCell>
                  <TableCell>
                    <div>{row.warehouseId ?? row.item.sku}</div>
                  </TableCell>
                  <TableCell>{row.item.name}</TableCell>
                  <TableCell>{row.variantNames ?? "---"}</TableCell>
                  {usesItemCustomization && (
                    <TableCell>{row.customization ?? "---"}</TableCell>
                  )}
                  <TableCell align="right">{row.packSize}</TableCell>
                  <TableCell align="right">{row.qty}</TableCell>
                  {role !== permissions.supplier && (
                    <TableCell align="right">
                      {formatMoney(row.initialCost)}
                    </TableCell>
                  )}
                  {uniqueOnes.length === 1 && (
                    <TableCell align="right">
                      {formatMoney(row.actualCost)}
                    </TableCell>
                  )}
                  {uniqueOnes.length > 1 && (
                    <TableCell
                      onClick={() => setTableView("itemized")}
                      tw="hover:underline cursor-pointer text-primary-600"
                      align="right"
                    >
                      See Itemized Costs
                    </TableCell>
                  )}
                  <TableCell align="right">
                    {formatMoney(row.actualCost * row.qty)}
                  </TableCell>
                </TableRow>
              );
            })}
            {purchaseOrderVariants.find((pov) => pov.isSetupFee) && (
              <>
                <TableRow>
                  <TableCell colSpan={headCells.length} />
                </TableRow>
                {purchaseOrderVariants
                  .filter((pov) => pov.isSetupFee)
                  .map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>Set Up Fee:</TableCell>
                      <TableCell colSpan={3}>{row.setupFeeDesc}</TableCell>
                      <TableCell
                        colSpan={role !== permissions.supplier ? 2 : 1}
                      />
                      {status === "draft" && (
                        <TableCell>
                          <IconButton
                            onClick={(event) => {
                              event.stopPropagation();
                              handleRemoveVariant(row.id);
                            }}
                            size="large"
                          >
                            <Cancel color="inherit" />
                          </IconButton>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
              </>
            )}
            <PurchaseOrderVariantTableFooter
              totalRowsFirstCellLength={totalRowsFirstCellLength}
              overview={true}
            />
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default PurchaseOrderVariantOverviewTable;
