/** @jsxImportSource @emotion/react */
import tw, { css, styled } from "twin.macro";

import { useState } from "react";
import { useSelector } from "react-redux";

import { Collapse } from "@mui/material";

export const FilterRow = styled.div(
  css(tw`flex flex-wrap justify-start items-end gap-x-3 gap-y-3`, {
    "& > *": tw`w-auto min-w-[9em]! max-w-[14em] flex-1`,
  })
);

export const MoreFiltersSection = ({ children, ...props }) => {
  const [showMore, setShowMore] = useState(true);
  const hasChips = useSelector((state) => state.filters.chipList.length > 0);
  return (
    <>
      <a
        href="#!"
        css={[
          tw`absolute text-sm cursor-pointer bottom-1 right-4 text-neutral-500`,
          hasChips && tw`bottom-4`,
        ]}
        role="button"
        onClick={(e) => {
          e.preventDefault();
          setShowMore(!showMore);
        }}
      >
        {showMore ? "Less Filters" : "More Filters"}
      </a>
      <Collapse in={showMore} {...props}>
        <div css={[tw`flex flex-col gap-4 mt-4 mb-2`, hasChips && tw`mb-0`]}>
          {children}
        </div>
      </Collapse>
    </>
  );
};
