import { useCallback } from "react";
import { NavigateOptions, Path, useLocation, useNavigate } from "react-router";

import { encodeSearchParams } from "@features/filters";

const useSetLocation = () => {
  const navigate = useNavigate();
  const currentLocation = useLocation();
  return useCallback(
    (
      location: {
        pathname?: string;
        search?: `?${string}` | "";
        hash?: `#${string}` | "";
        searchParams?: Record<string, any>;
      },
      navigateOptions?: NavigateOptions
    ) => {
      const searchFromParams =
        location.searchParams &&
        `?${encodeSearchParams(location.searchParams)}`;
      const newLocation: Path = {
        pathname: currentLocation.pathname,
        search: currentLocation.search,
        hash: currentLocation.hash,
        ...location,
        ...(searchFromParams ? { search: searchFromParams } : {}),
      };
      navigate(
        newLocation.pathname + newLocation.search + newLocation.hash,
        navigateOptions
      );
    },
    [
      currentLocation.pathname,
      currentLocation.search,
      currentLocation.hash,
      navigate,
    ]
  );
};

export default useSetLocation;
