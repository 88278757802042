/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect } from "react";
import { useSelector } from "react-redux";

import DashCard from "@components/Dashboard/DashCard";
import { Contained, DashboardGrid } from "@components/StyledComponents";
import DocTitle from "@utility/DocTitle";

/*
The reports dashboard uses cards similar to those on the actual user dashboards to display
links to various reports available in Brandhub. Each card has a disabled clause that can
be used if the report is conditionally visible to certain user roles.
*/

const ReportDashboard = () => {
  const {
    organization: {
      budgetLocation,
      usesInventory,
      allocationLocation,
      hasBeacons,
    },
    role,
  } = useSelector((state: any) => state.currentUser);

  let cardData = [
    {
      titleText: "Order Window Summary",
      link: "/reports/order-window-summary",
      icon: "https://res.cloudinary.com/brandhub/image/upload/v1617273999/prod/Icons/SELECT_Icons_Retail_-_On_Premise_fc9yxt.png",
      disabled: false,
      info: "Provides a summary of the total aggregated quantities for each item ordered on a Pre-Order Window",
      id: "order-window-summary",
    },
    {
      titleText: "Order Window Detail",
      link: "/reports/order-window-detail",
      icon: "https://res.cloudinary.com/brandhub/image/upload/v1617273999/prod/Icons/SELECT_Icons_Real-Time_Reporting_umtxqn.png",
      disabled: false,
      info: "Gives detailed order information for all items included in a Pre-Order Window, including who ordered it and where the item is shipping too.",
      id: "order-window-detail",
    },
    {
      titleText: "Shipped Orders Report",
      link: "/reports/shipped-orders-report",
      icon: "https://res.cloudinary.com/brandhub/image/upload/v1617273999/prod/Icons/SELECT_Icons_Global_Network_nmevmk.png",
      disabled: false,
      info: "The Shipped Orders Report is a great tool for end-of-month reporting and will give a detailed report on all items ordered within a given date range.",
      id: "shipped-orders-report",
    },
    {
      titleText: "Pre-Order Non-Participants",
      link: "/reports/pre-order-user-report",
      icon: "https://res.cloudinary.com/brandhub/image/upload/v1617273999/prod/Icons/SELECT_Icons_Countless_Vendors_xdw0mc.png",
      disabled: false,
      info: "Gives you a summary of users that haven't ordered on a specific pre-order window yet.",
      id: "pre-order-non-participants",
    },
    {
      titleText: "Promotion Summary Report",
      link: "/reports/promo-code-report",
      icon: "https://res.cloudinary.com/brandhub/image/upload/v1617273999/prod/Icons/SELECT_Icons_Order_Management_m5zx9c.png",
      disabled: false,
      info: "Gives you an overview of all promotions used within a given date range, or for specific promotions.",
      id: "promotion-summary-report",
    },
    {
      titleText: "Item List Report",
      link: "/reports/product-list-report",
      icon: "https://res.cloudinary.com/brandhub/image/upload/v1617273998/prod/Icons/SELECT_Icons_Shopper_Marketing_-_POS_el3abn.png",
      disabled: false,
      info: "Provides a detailed list of all the items that are currently available, or archived, along with any available inventory they might have.",
      id: "item-list-report",
    },
    {
      titleText: "Franchise Order History Report",
      link: "/reports/franchise-order-history-report",
      icon: "https://res.cloudinary.com/brandhub/image/upload/v1617273999/prod/Icons/SELECT_Icons_Retail_-_On_Premise_fc9yxt.png",
      disabled: false,
      info: "Franchise Order History",
      id: "franchise-order-history-report",
    },
  ];

  if (Boolean(budgetLocation)) {
    cardData = cardData.concat([
      {
        titleText: "Budget Adjustment Report",
        link: "/reports/budget-adjustment-report",
        icon: "https://res.cloudinary.com/brandhub/image/upload/v1617273999/prod/Icons/SELECT_Icons_Precise_Spend_Tracking_baw9z1.png",
        disabled: false,
        info: "Gives a detailed ledger of all adjustments and debits on the requested budget. ",
        id: "budget-adjustment-report",
      },
      {
        titleText: "Budget Summary Export",
        link: "/budgets",
        icon: "https://res.cloudinary.com/brandhub/image/upload/v1617273999/prod/Icons/SELECT_Icons_Sell-In_Tools_m4q2a0.png",
        disabled: false,
        info: "Gives an overview of all budgets in their current state. It should be noted that there needs to be an active pre-order window in order to run the report.",
        id: "budget-summary",
      },
    ]);
  }

  if (usesInventory) {
    cardData = cardData.concat([
      {
        titleText: "Inventory Snapshot Report",
        link: "/reports/inventory-snapshot",
        icon: "https://res.cloudinary.com/brandhub/image/upload/v1617273999/prod/Icons/SELECT_Icons_Distribution_bvwczo.png",
        disabled: false,
        info: "Gives a snapshot of inventory items; current inventory levels and order quantities over that past year.",
        id: "inventory-snapshot",
      },
      {
        titleText: "Inventory Velocity Report",
        link: "/reports/inventory-velocity",
        icon: "https://res.cloudinary.com/brandhub/image/upload/v1617273998/prod/Icons/SELECT_Icons_Top_Products_-_Best_Sellers_hge1gt.png",
        disabled: false,
        info: "Gives a detailed account of inventory items allowing you to see how much inventory has been ordered each month.",
        id: "inventory-velocity",
      },
      {
        titleText: "Inventory Check Report",
        link: "/reports/inventory-check",
        icon: "https://res.cloudinary.com/brandhub/image/upload/v1609786359/prod/Icons/on-demand-order-and-current-quotes_cmoaqi.png",
        disabled: false,
        info: "Gives you a quick snapshot of a single SKU; inventory level, pending orders, and allocations.",
        id: "inventory-check",
      },
    ]);
  }

  if (Boolean(allocationLocation)) {
    cardData = cardData.concat(
      [
        {
          titleText: "Allocation Summary Report",
          link: "/reports/variant-allocation-report",
          icon: "https://res.cloudinary.com/brandhub/image/upload/v1617273999/prod/Icons/SELECT_Icons_Inventory_hqowaj.png",
          disabled: false,
          info: "Gives a shapshot of current allocations, orders against them, and their balances.",
          id: "allocation-report",
        },
      ],
      [
        {
          titleText: "Allocation Detail Report",
          link: "/reports/allocation-snapshot-report",
          icon: "https://res.cloudinary.com/brandhub/image/upload/v1617273999/prod/Icons/SELECT_Icons_Inventory_hqowaj.png",
          disabled: false,
          info: "Gives a shapshot of current allocations, orders against them, and their balances.",
          id: "allocation-snapshot-report",
        },
      ]
    );
  }

  if (hasBeacons && ["admin", "super"].includes(role)) {
    cardData = cardData.concat([
      {
        titleText: "Beacon Locations Report",
        link: "/reports/beacon-location-report",
        icon: "https://res.cloudinary.com/brandhub/image/upload/v1617273999/prod/Icons/SELECT_Icons_Inventory_hqowaj.png",
        disabled: false,
        info: "Shows all locations that beacons have been found in for a program.",
        id: "beacon-location-report",
      },
    ]);
  }

  // If the user has access to allocations and is an orderer, only show the allocation report card
  if (Boolean(allocationLocation) && role === "orderer") {
    cardData = [
      {
        titleText: "Allocation Report",
        link: "/reports/variant-allocation-report",
        icon: "https://res.cloudinary.com/brandhub/image/upload/v1617273999/prod/Icons/SELECT_Icons_Inventory_hqowaj.png",
        disabled: false,
        info: "Gives a shapshot of current allocations, orders against them, and their balances.",
        id: "allocation-report",
      },
    ];
  }

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Contained>
      <DocTitle title={"Reports"} />
      <DashboardGrid>
        {cardData.map((data) =>
          !data.disabled ? <DashCard key={data.id} data={data} /> : null
        )}
      </DashboardGrid>
    </Contained>
  );
};

export default ReportDashboard;
