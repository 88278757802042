/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useParams } from "react-router";

import { CircularProgress } from "@mui/material";

import { Contained } from "@components/StyledComponents";
import {
  AwardQuoteButton,
  PurchaserQuote,
  QuoteStatus,
  SupplierQuote,
  useQuoteQuery,
} from "@features/quotes";
import { RfqItemReference } from "@features/rfqs";
import { Title } from "@features/ui";
import DocTitle from "@utility/DocTitle";
import permissions from "@utils/permissions";
import useRoleIs from "@utils/useRoleIs";

import ErrorPage from "./ErrorPage";

const Quote = () => {
  const { id } = useParams();

  const isSupplier = useRoleIs()(permissions.supplier);
  const { data: quote, error } = useQuoteQuery(id);
  if (error && !quote) return <ErrorPage error={error} />;
  if (!quote) return <CircularProgress />;

  return (
    <Contained tw="pb-6 max-w-screen-xl">
      <DocTitle title={`Quote #${quote.id}`} />
      <div tw="flex items-start justify-between mb-6">
        <div>
          <Title
            backUrl={
              isSupplier ? "/quotes" : `/rfqs/${quote.requestForQuote.id}`
            }
          >
            <div tw="flex items-baseline flex-wrap gap-x-4">
              <div>{quote.requestForQuote.itemName}</div>
              <div tw="flex gap-4 uppercase tracking-wide font-medium text-neutral-600 text-sm">
                {quote.supplierName} - round {quote.requestForQuote.round}
                {quote.status !== "sent" && (
                  <QuoteStatus status={quote.status} />
                )}
              </div>
            </div>
          </Title>
        </div>
        {!isSupplier && ["complete", "awarded"].includes(quote.status) && (
          <AwardQuoteButton quote={quote} />
        )}
      </div>
      <div tw="grid lg:grid-cols-[2fr 1fr] gap-6 max-w-screen-xl">
        {isSupplier ? (
          <SupplierQuote quote={quote} />
        ) : (
          <PurchaserQuote quote={quote} />
        )}
        <div tw="flex flex-col gap-6 relative">
          <RfqItemReference rfq={quote.requestForQuote} />
        </div>
      </div>
    </Contained>
  );
};

export default Quote;
