/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect } from "react";
import { useSelector } from "react-redux";

import PropTypes from "prop-types";

import ChannelAutoComplete from "@components/Utility/AutoCompleteFields/ChannelAutoComplete";
import { StyledSelectInput } from "@utils/forms";
import FilterSyncResourceAutocomplete from "@utils/forms/FilterSyncResourceAutocomplete";

import { FilterRow } from "../Filtering/FilterComponents";
import { StyledButton } from "../StyledComponents";
import GroupAutoComplete from "../Utility/AutoCompleteFields/GroupAutoComplete";

const FiltersItemReports = ({
  reset,
  setReset,
  handleFilters,
  handleSearch,
  filterType,
}) => {
  const {
    groups,
    role,
    organization: { usesChannels },
  } = useSelector((state) => state.currentUser);
  const isLoading = useSelector((state) => state.globalState.isLoading);
  const categories = useSelector((state) => state.groupCategories.categories);
  const status = useSelector((state) => state.filters.status) || "all";
  const itemVisibilityCase =
    useSelector((state) => state.filters.itemVisibilityCase) || "";

  const orderWindowIds = useSelector((state) => state.filters.orderWindowIds);

  let userCategories =
    groups.length === 0
      ? categories
      : categories.map((cat) => {
          let currentGroups = groups.filter(
            (g) => g.groupCategoryId === cat.id
          );
          if (currentGroups.length > 0) {
            return {
              ...cat,
              groups: currentGroups,
            };
          } else return { ...cat, groups: [...cat.groups] };
        });

  const programIdParams = {
    filter: {
      ...(orderWindowIds &&
        orderWindowIds.length > 0 && {
          orderWindowIds: orderWindowIds.map((owi) => owi.id),
        }),
    },
  };

  useEffect(() => {
    handleFilters(status, "status", filterType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, filterType]);

  return (
    <div>
      <FilterRow>
        <StyledSelectInput
          label="Item Status"
          value={status}
          onChange={(e) => {
            handleFilters(e.target.value, "status", filterType);
          }}
          options={[
            { id: "all", name: "All" },
            { id: "active", name: "Active" },
            { id: "archived", name: "Archived" },
            { id: "draft", name: "Draft" },
          ]}
        />

        {userCategories?.map((cat) => (
          <GroupAutoComplete
            key={cat.id}
            handleChange={handleFilters}
            reset={reset}
            setReset={setReset}
            filterType={filterType}
            categoryLabel={cat.name}
            options={cat.groups}
          />
        ))}

        <FilterSyncResourceAutocomplete
          handleChange={handleFilters}
          reset={reset}
          setReset={setReset}
          filterType={filterType}
          resource={"programs"}
          filterName={"programId"}
          label={"Program"}
          requestParams={programIdParams}
          single
        />

        <StyledSelectInput
          label="Visibility"
          value={itemVisibilityCase}
          onChange={(e) => {
            handleFilters(e.target.value, "itemVisibilityCase", filterType);
          }}
          options={[
            { id: "", name: "No visiblity filter" },
            { id: "expiring-soon", name: "Item will expire within 3 months" },
            {
              id: "future-visible-start-date",
              name: "Item has future visible start date",
            },
            {
              id: "past-visible-end-date",
              name: "Item has past visible end date",
            },
          ]}
        />

        {role === "super" && Boolean(usesChannels) && (
          <ChannelAutoComplete
            handleChange={handleFilters}
            reset={reset}
            setReset={setReset}
            filterType={filterType}
          />
        )}

        <StyledButton cta onClick={handleSearch} disabled={isLoading}>
          GENERATE REPORT
        </StyledButton>
      </FilterRow>
    </div>
  );
};

FiltersItemReports.propTypes = {
  reset: PropTypes.bool.isRequired,
  setReset: PropTypes.func.isRequired,
  handleFilters: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
};

export default FiltersItemReports;
