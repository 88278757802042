/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import { Close } from "@mui/icons-material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";

import _ from "lodash";

import { StyledButton } from "@components/StyledComponents";
import { Item } from "@models";
import { ControlledCheckboxInput } from "@utils/forms";

import { variantName } from "../items/helpers";

type VariantSelectionModalProps = {
  item: Item;
  orderType: "on-demand" | "inventory" | "pre-order";
  orderSetVariants: any[];
  onClose: () => void;
  createOrUpdateOrderSet: (variantIds: string[]) => Promise<void>;
};

const filterActive = (items) => _.filter(items, "isActive");

const VariantSelectionModal = ({
  onClose,
  item,
  createOrUpdateOrderSet,
  orderSetVariants,
  orderType,
}: VariantSelectionModalProps) => {
  const items = [item]; // keep support for multiple items for now
  const isInventoryOrder = orderType === "inventory";
  const {
    id: userId,
    currentTerritoryId,
    organization: { orderLimitType },
  } = useSelector((state: any) => state.currentUser);

  const existingVariantIds = orderSetVariants.map((osv) => osv.variant.id);

  const variantInventories = isInventoryOrder
    ? Object.fromEntries(
        items.flatMap((item) =>
          item.variants.map((variant) => {
            // ignore inventory check for shipstation warehouse items
            if (item.warehouse === "shipstation") return [variant.id, Infinity];

            const filteredAllocations = variant.variantAllocations.filter(
              (a) =>
                (a.user && a.user.id === userId) ||
                (a.territory && a.territory.id === currentTerritoryId)
            );

            const allocationExists =
              filteredAllocations.length === 0 ? false : true;

            const allocationTotal = _.sumBy(
              filteredAllocations,
              "availableQty"
            );

            const total =
              orderLimitType === "allocation-only"
                ? allocationTotal
                : orderLimitType === "allocation-plus-global"
                  ? variant.globalAvailableToOrderQty + allocationTotal
                  : allocationExists
                    ? allocationTotal
                    : variant.globalAvailableToOrderQty;
            return [variant.id, total];
          })
        )
      )
    : {};
  const outOfStockIds = Object.keys(variantInventories).filter(
    (id) => variantInventories[id] === 0
  );

  // Ids of variants from items with only a single, default, variant
  const onlyChildVariantIds = items.flatMap((item) =>
    item.variants.length === 1 ? [item.variants[0].id] : []
  );

  // These can't be changed by the user
  const immutableValues = {
    // set all out of stock variants to false
    ...Object.fromEntries(outOfStockIds.map((id) => [id, false])),
    // set all in-cart variants to true
    ...Object.fromEntries(existingVariantIds.map((id) => [id, true])),
    // set all only-child variants to true
    ...Object.fromEntries(onlyChildVariantIds.map((id) => [id, true])),
  };
  const { control, handleSubmit, reset, getValues } = useForm({
    defaultValues: immutableValues,
  });
  const [loading, setLoading] = useState<boolean>(false);

  const handleSelectItemVariants = (item: Item) => {
    const formValues = getValues();

    const variantIds = filterActive(item.variants).map((variant) => variant.id);
    const inStockVariantIds = _.difference(variantIds, outOfStockIds);
    const allItemVariantsSelected =
      _.difference(
        inStockVariantIds,
        Object.keys(formValues).filter((key) => formValues[key])
      ).length === 0;

    const variantIdObject = Object.fromEntries(
      inStockVariantIds.map((id) => [id, !allItemVariantsSelected])
    );
    reset((data) => ({ ...data, ...variantIdObject, ...immutableValues }));
  };

  const handleAddToCart = async (data) => {
    setLoading(true);
    const selectedVariantIds = Object.keys(data).filter((key) => data[key]);
    const variantIdsToCreate = _.difference(
      selectedVariantIds,
      existingVariantIds
    );
    await createOrUpdateOrderSet(variantIdsToCreate);
    setLoading(false);
    onClose();
  };

  return (
    <Dialog open onClose={onClose} fullWidth>
      <DialogTitle tw="flex justify-between items-start">
        Select Variants
        <IconButton onClick={onClose} edge="end">
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form tw="space-y-6">
          {items.map(
            (item) =>
              item.variants.length > 1 && (
                <div key={item.id}>
                  <h3 tw="text-neutral-900 font-medium">{item.name}</h3>
                  <a
                    href="#!"
                    role="button"
                    tw="inline-block mb-2 text-sm text-primary-600 underline"
                    onClick={(e) => {
                      e.preventDefault();
                      handleSelectItemVariants(item);
                    }}
                  >
                    Select All
                  </a>
                  <div tw="grid grid-cols-2 gap-2">
                    {filterActive(_.sortBy(item.variants, "orderPosition")).map(
                      (variant) => {
                        const variantInventory =
                          isInventoryOrder && variantInventories[variant.id];
                        const inCart = existingVariantIds.includes(variant.id);
                        return (
                          !variant.isDefault && (
                            <div
                              key={variant.id}
                              tw="flex flex-wrap items-center justify-between gap-x-2
                                  rounded-lg border border-neutral-200 p-2"
                            >
                              <ControlledCheckboxInput
                                tw="-my-2"
                                control={control}
                                labelProps={{ style: tw`text-sm!` }}
                                name={variant.id}
                                label={variantName(variant)}
                                disabled={inCart || variantInventory === 0}
                              />
                              <div tw="text-sm text-neutral-500 whitespace-nowrap text-right">
                                {!inCart &&
                                  variantInventory === 0 &&
                                  "Out of stock"}
                                {inCart && "In Cart"}

                                {Number(variantInventory) > 0 &&
                                  variantInventory !== Infinity && // Infinity inventory are ignored
                                  !inCart && (
                                    <span tw=" text-primary-800">
                                      {variantInventory} available
                                    </span>
                                  )}
                              </div>
                            </div>
                          )
                        );
                      }
                    )}
                  </div>
                </div>
              )
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <StyledButton
          cta
          onClick={handleSubmit(handleAddToCart)}
          loading={loading}
        >
          Add to Cart
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
};

export default VariantSelectionModal;
