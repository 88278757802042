/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { useState } from "react";
import { Link } from "react-router";

import { ArrowForward } from "@mui/icons-material";

import { sortBy } from "lodash";

import { StyledButton } from "../../../components/StyledComponents";
import { OrderWindow, Program } from "../../../models";
import { formatUtcDate } from "../../../utility/utilityFunctions";
import {
  ProgramOrderWindowAssignmentModal,
  isOrderWindowActive,
} from "../../orderWindows";
import BlockCard from "../../ui/BlockCard";

const OrderWindowCard = ({
  orderWindow,
  active,
}: {
  orderWindow: OrderWindow;
  active: boolean;
}) => {
  return (
    <div
      css={[
        tw`hover:bg-neutral-100 transition-colors rounded py-1.5 px-3 -mx-3`,
        active && tw`bg-primary-50`,
      ]}
    >
      <div tw="leading-snug flex justify-between items-center">
        <Link
          to={`/admin/order-windows/edit/${orderWindow.id}`}
          target="_blank"
          tw="hover:underline text-neutral-800"
        >
          {orderWindow.name}
        </Link>
        {active && (
          <div tw="bg-primary-100 tracking-wide text-primary-900 py-0.5 px-2 -mr-1.5 rounded text-xs">
            Active
          </div>
        )}
      </div>
      <div tw="text-sm text-neutral-500 mt-0.5">
        {formatUtcDate(orderWindow.openDate, "MMM d")} -{" "}
        {formatUtcDate(orderWindow.closeDate, "PP")}
        <span>
          <ArrowForward tw="text-base mx-2" />
          In Market {formatUtcDate(orderWindow.inMarketDate, "PP")}
        </span>
      </div>
    </div>
  );
};

const OrderWindows = ({
  program,
  handleSaveOrderWindows,
}: {
  program: Program;
  handleSaveOrderWindows: (orderWindowIds: string[]) => Promise<any>;
}) => {
  const [assignmentModalOpen, setAssignmentModalOpen] = useState(false);
  const sortedOrderWindows = sortBy(program.orderWindows, "closeDate");

  // Split order windows into active and inactive
  const [activeOws, inactiveOws] = sortedOrderWindows.reduceRight(
    (acc, ow) => {
      acc[isOrderWindowActive(ow) ? 0 : 1].push(ow);
      return acc;
    },
    [[], []] as OrderWindow[][]
  );

  return (
    <BlockCard title="Order Windows">
      <>
        {program.orderWindows.length === 0 && (
          <p tw="text-neutral-600 text-lg">Not assigned to any Order Windows</p>
        )}
        <div tw="max-h-72 -mx-6 px-6 overflow-y-auto">
          {activeOws.map((orderWindow) => (
            <OrderWindowCard
              key={orderWindow.id}
              orderWindow={orderWindow}
              active={true}
            />
          ))}
          {inactiveOws.map((orderWindow) => (
            <OrderWindowCard
              key={orderWindow.id}
              orderWindow={orderWindow}
              active={false}
            />
          ))}
        </div>
        <StyledButton
          tw="mt-3 normal-case text-sm font-normal"
          outlined
          onClick={() => setAssignmentModalOpen(true)}
        >
          {program.orderWindows.length === 0
            ? "Assign Order Window"
            : "Manage Order Windows"}
        </StyledButton>
        {assignmentModalOpen && (
          <ProgramOrderWindowAssignmentModal
            onClose={() => setAssignmentModalOpen(false)}
            program={program}
            handleSave={handleSaveOrderWindows}
          />
        )}
      </>
    </BlockCard>
  );
};

export default OrderWindows;
