/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect, useRef } from "react";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { TextField, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import OrderWindowTable from "@components/BrandHQ/OrderWindows/OrderWindowTable";
import {
  FixedHeightScrollLastChild,
  OpaqueCard,
  StyledButton,
  TableLoading,
} from "@components/StyledComponents";
import {
  fetchFilteredOrderWindows,
  fetchNextFilteredOrderWindows,
} from "@redux/slices/orderWindows/orderWindowSlice";
import DocTitle from "@utility/DocTitle";

import { useDetailedInput } from "../hooks/inputs/useDetailedInput";

/*
The order window admin gives the user access to all order windows in the organization, filterable by name, and
gives access to the create and update functionality for the windows.
*/

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  inputField: {
    marginBottom: "15px",
    width: "33%",
    [theme.breakpoints.down("md")]: {
      width: "66%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const OrderWindowAdmin = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { filteredWindows, isLoading, isNextLoading, nextLink } = useSelector(
    (state) => state.orderWindows
  );

  const handleBottomScroll = () => {
    if (nextLink && !isNextLoading) {
      if (scrollRef.current.scrollTop !== 0) {
        dispatch(fetchNextFilteredOrderWindows(nextLink));
      }
    }
  };

  const scrollRef = useBottomScrollListener(handleBottomScroll, {
    offset: 300,
    debounceOptions: {
      leading: true,
      trailing: false,
    },
  });

  const debounce = useRef(null);

  const handleWindowClick = (id, type) => {
    navigate(
      id ? `/admin/order-windows/${type}/${id}` : `/admin/order-windows/${type}`
    );
  };

  const handleSearch = (value, _type, _filter) => {
    clearTimeout(debounce.current);

    debounce.current = setTimeout(() => {
      dispatch(fetchFilteredOrderWindows(value));
    }, 250);
  };

  const { value: search, bind: bindSearch } = useDetailedInput(
    "",
    handleSearch
  );

  useEffect(() => {
    dispatch(fetchFilteredOrderWindows());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <DocTitle title={"Order Window Admin"} />
      <FixedHeightScrollLastChild>
        <header className={classes.titleBar}>
          <Typography className={classes.titleText}>
            Order Window Admin
          </Typography>
          <div className={classes.innerConfigDiv}>
            <StyledButton cta onClick={() => handleWindowClick(null, "new")}>
              NEW ORDER WINDOW
            </StyledButton>
          </div>
        </header>
        <TextField
          size="small"
          className={classes.inputField}
          variant="outlined"
          color="secondary"
          name="search"
          type="text"
          label="Search"
          value={search}
          {...bindSearch}
        />
        <OpaqueCard tw="p-0 relative overflow-hidden">
          <OrderWindowTable
            handleWindowClick={handleWindowClick}
            windows={filteredWindows}
            isLoading={isLoading}
            scrollRef={scrollRef}
          />
          <TableLoading isNextLoading={isNextLoading} />
        </OpaqueCard>
      </FixedHeightScrollLastChild>
    </>
  );
};

OrderWindowAdmin.propTypes = {};

export default OrderWindowAdmin;
