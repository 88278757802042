/** @jsxImportSource @emotion/react */
import "twin.macro";
import tw from "twin.macro";

import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { Dialog, DialogTitle, IconButton, Tooltip } from "@mui/material";

import { DndContext, useDroppable } from "@dnd-kit/core";
import { useQueryClient } from "@tanstack/react-query";
import { axiosPost } from "src/api/axiosCalls";

import { StyledButton } from "@components/StyledComponents";
import { itemsKeyFactory } from "@features/items";
import { setError } from "@redux/slices/errorSlice";
import {
  imageExtensions,
  useUploadFileToCloudinary,
} from "@services/cloudinary";
import asyncPool from "@utility/asyncPool";

import { postImageToAPI } from "./helper";

const ItemImageUploadModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const uploadFileToCloudinary = useUploadFileToCloudinary();
  const inputRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [imageFiles, setImageFiles] = useState<{ [key: string]: File }>({});

  // Hash map of item names to their corresponding item ids, corresponding item id is null if not found
  const [validationResults, setValidationResults] = useState<{
    [key: string]: string | null;
  }>({});
  // Array of item ids that have been successfully uploaded
  const [uploadedImages, setUploadedImages] = useState<string[]>([]);

  const numNotFound = Object.values(validationResults).filter(
    (result) => result === null
  ).length;
  const numFound = Object.values(validationResults).filter(
    (result) => result !== null
  ).length;

  const { setNodeRef } = useDroppable({
    id: "droppable",
  });

  const handleDragEnter = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      setDragging(true);
    },
    []
  );

  const handleDragLeave = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      setDragging(false);
    },
    []
  );

  const processFiles = useCallback((files: File[] | null) => {
    const newFiles: { [key: string]: File } = {};

    if (!files) return newFiles;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const extension = file.name.split(".").pop()?.toLowerCase();
      if (extension && imageExtensions.includes(extension)) {
        // Process the image file
        newFiles[file.name] = file;
      }
    }

    return newFiles;
  }, []);

  const handleFileSelect = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = Array.from(event.target.files || []);
      const newFiles = processFiles(files);
      setImageFiles((prevFiles) => ({ ...prevFiles, ...newFiles }));
    },
    [processFiles]
  );

  const handleDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();

      const files: File[] = [];
      if (!event.dataTransfer.items) return;

      for (let i = 0; i < event.dataTransfer.items.length; i++) {
        if (event.dataTransfer.items[i].kind === "file") {
          const file = event.dataTransfer.items[i].getAsFile();
          if (file) {
            files.push(file);
          }
        }
      }

      const newFiles = processFiles(files);
      setImageFiles((prevFiles) => ({ ...prevFiles, ...newFiles }));
    },
    [processFiles]
  );

  const uploadImage = async (item: [string, File], _: [string, File][]) => {
    const [name, file] = item;
    if (!name) {
      throw new Error("Name is null");
    }

    const itemId = name.split(".")[0];

    // Skip items that weren't found in the database as well as items that have already been uploaded
    if (!validationResults[itemId] || uploadedImages.includes(itemId)) {
      return;
    }
    const result = await uploadFileToCloudinary(file);

    if (!result.secure_url) {
      return;
    }

    const validationResult = validationResults[itemId];
    if (!validationResult) {
      throw new Error(`No validation result for item ID ${itemId}`);
    }

    await postImageToAPI(validationResult, result);

    setUploadedImages((prevImages) => [...prevImages, itemId]);
  };

  const uploadImagesToCloudinary = async () => {
    setLoading(true);
    const iterable = Object.entries(imageFiles);
    const { errors } = await asyncPool(5, iterable, uploadImage);

    if (errors && errors.length > 0) {
      dispatch(
        setError({ error: errors.join("\n"), source: "Cloudinary Upload" })
      );
      console.error(errors);
    }
    setLoading(false);

    queryClient.invalidateQueries({ queryKey: itemsKeyFactory._def });
  };

  const handleDelete = (
    event: React.MouseEvent<HTMLButtonElement>,
    name: string,
    filenameWithoutExtension: string
  ) => {
    event.stopPropagation(); // Prevent triggering the drop event
    setImageFiles((prevFiles) => {
      const updatedFiles = { ...prevFiles };
      delete updatedFiles[name];
      return updatedFiles;
    });
    setValidationResults((prevResults) => {
      const updatedResults = { ...prevResults };
      delete updatedResults[filenameWithoutExtension];
      return updatedResults;
    });
    setUploadedImages((prevImages) =>
      prevImages.filter((image) => image !== filenameWithoutExtension)
    );
  };

  useEffect(() => {
    const validateItemNames = async () => {
      if (imageFiles && Object.keys(imageFiles).length > 0) {
        try {
          const response: any = await axiosPost(
            "/api/items/verify-customer-identifiers",
            {
              data: {
                identifiers: Object.keys(imageFiles).map((imageFile) => {
                  return imageFile.split(".")[0];
                }),
              },
            },
            false
          );
          if (!response.error && response.data?.data?.identifiers) {
            setValidationResults(response.data.data.identifiers);
          } else {
            throw new Error(response.error);
          }
        } catch (error: any) {
          dispatch(
            setError({
              error: error.toString(),
              source: "Item Upload CSV File",
            })
          );
        }
      }
    };

    validateItemNames();
  }, [imageFiles, dispatch]);

  return (
    <Dialog
      open={open}
      onClose={loading ? undefined : onClose}
      disableEscapeKeyDown={loading}
    >
      <DialogTitle>Item Image Upload</DialogTitle>
      <div tw="mx-6 mb-6">
        <div tw="flex justify-center mb-4">
          Select or drag & drop images for items below.
        </div>
        <div tw="flex justify-center mb-4">
          * Image filenames must match up to the existing item name or brandhub
          generated SKU that the image is being added to.
        </div>

        <div tw="flex justify-center">
          <StyledButton
            cta
            disabled={loading}
            onClick={() => {
              inputRef.current?.click();
            }}
            tw="mb-2 self-center"
          >
            Select Images
          </StyledButton>
          <input
            type="file"
            multiple
            hidden
            ref={inputRef}
            onChange={handleFileSelect}
          />
        </div>

        <div tw="h-48 mb-4">
          <DndContext>
            <div
              ref={setNodeRef}
              onDrop={handleDrop}
              onDragOver={(event) => event.preventDefault()} // Necessary to allow dropping
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragLeave}
              css={{
                "&": tw`flex items-center justify-center h-full transition-colors duration-200 ease-in-out border-2 rounded-md`,
                borderColor: dragging ? "black" : "",
                backgroundColor: dragging ? "#f3f3f3" : "", // Change background color when dragging
              }}
            >
              {Object.values(imageFiles).length > 0 ? (
                <div tw="grid grid-flow-row grid-cols-3 gap-2 justify-items-center overflow-auto h-48 auto-rows-max">
                  {" "}
                  {Object.entries(imageFiles).map(([name, file], index) => {
                    const filenameWithoutExtension = name.split(".")[0];
                    return (
                      <div
                        key={index}
                        css={[
                          tw`flex items-center px-2 py-1 space-x-2 text-sm text-center text-white rounded-md max-h-10`,
                          loading &&
                          validationResults[filenameWithoutExtension] !==
                            null &&
                          (validationResults[filenameWithoutExtension] ===
                            undefined ||
                            !uploadedImages.includes(filenameWithoutExtension))
                            ? tw`bg-gray-300 animate-pulse`
                            : validationResults[filenameWithoutExtension]
                              ? uploadedImages.includes(
                                  filenameWithoutExtension
                                )
                                ? tw`bg-green-600`
                                : tw`bg-blue-600`
                              : tw`bg-red-600`,
                        ]}
                      >
                        <span>{file.name}</span>
                        <Tooltip title="Delete">
                          <IconButton
                            size="small"
                            onClick={(event) =>
                              handleDelete(
                                event,
                                file.name,
                                filenameWithoutExtension
                              )
                            }
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div tw="text-center">
                  <AddIcon color={dragging ? "primary" : "action"} />
                </div>
              )}
            </div>
          </DndContext>
        </div>
        {numNotFound > 0 && (
          <div tw="text-red-600 flex justify-center mx-4 mb-4">
            {`${numNotFound} image${
              numNotFound > 1 ? "s" : ""
            } did not match with an item in the database`}
          </div>
        )}
        {uploadedImages.length > 0 && (
          <div tw="flex justify-center mx-4 mb-4">
            {`${uploadedImages.length} image${
              uploadedImages.length > 1 ? "s" : ""
            } successfully uploaded`}
          </div>
        )}
        <div tw="flex justify-center">
          <StyledButton
            cta
            disabled={
              numFound - uploadedImages.length === 0 ||
              Object.keys(validationResults).every((key) =>
                uploadedImages.includes(key)
              ) ||
              loading
            }
            onClick={() => {
              uploadImagesToCloudinary();
            }}
          >
            Upload {numFound - uploadedImages.length} images to Cloudinary
          </StyledButton>
          <StyledButton outlined disabled={loading} onClick={onClose} tw="ml-2">
            Done
          </StyledButton>
        </div>
      </div>
    </Dialog>
  );
};

export default ItemImageUploadModal;
