/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";

import {
  Contained,
  OpaqueCard,
  SectionTitle,
  StyledButton,
} from "@components/StyledComponents";
import { Title } from "@features/ui";
import { ConceptBoard } from "@models/ConceptBoard";
import {
  ControlledDateInput,
  ControlledResourceAutocomplete,
  ControlledTextInput,
} from "@utils/forms";

import { useConceptBoardContext } from "./ConceptBoardContext";
import ConceptCategoryAutocomplete from "./components/ConceptCategoryAutocomplete";
import { useUpdateConceptBoardMutation } from "./data";

const formValuesFromConceptBoard = (board: ConceptBoard) => ({
  id: board.id,
  title: board.title ?? "",
  description: board.description ?? "",
  budget: board.budget ?? "",
  deadline: board.deadline ?? "",
  supplierIds: board.suppliers.map((s) => s.id),
  conceptCategoryIds: board.conceptCategories.map((c) => c.id),
});
type FormValues = ReturnType<typeof formValuesFromConceptBoard>;

const ConceptBoardForm = () => {
  const navigate = useNavigate();
  const data = useConceptBoardContext();
  const updateBoardMutation = useUpdateConceptBoardMutation();
  const { control, handleSubmit } = useForm({
    defaultValues: formValuesFromConceptBoard(data),
  });

  const handleSave = (formData: FormValues) => {
    updateBoardMutation.mutate(formData, {
      onSuccess: () => navigate(`/create/boards/${data.id}`),
    });
  };

  return (
    <Contained tw="items-start">
      <div tw="flex items-center justify-between">
        <Title backUrl={`/create/boards/${data.id}`}>{data.title}</Title>
      </div>
      <OpaqueCard tw="p-6 w-2/3">
        <form onSubmit={handleSubmit(handleSave)}>
          <div tw="flex flex-col gap-8">
            <div>
              <SectionTitle>Board Title</SectionTitle>
              <ControlledTextInput
                name="title"
                control={control}
                {...(data.title === "New Board" && {
                  autoFocus: true,
                  onFocus: (e) => e.target.select(),
                })}
              />
            </div>
            <div>
              <SectionTitle>Brief</SectionTitle>
              <ControlledTextInput
                name="description"
                multiline
                minRows={5}
                control={control}
                label={
                  "Align creative direction by outlining your goals and objectives. "
                }
              />
            </div>
            <div>
              <SectionTitle>Info</SectionTitle>
              <div tw="space-y-4">
                <div tw="flex gap-6">
                  <ControlledTextInput
                    label="Budget"
                    control={control}
                    name="budget"
                  />
                  <ControlledDateInput
                    label="Deadline"
                    control={control}
                    name="deadline"
                  />
                </div>
              </div>
            </div>

            <div>
              <SectionTitle>Suppliers</SectionTitle>
              <ControlledResourceAutocomplete
                resource="suppliers"
                multiple
                control={control}
                name="supplierIds"
              />
            </div>
            <div>
              <SectionTitle>Categories of interest</SectionTitle>
              <ConceptCategoryAutocomplete
                control={control}
                name="conceptCategoryIds"
                label=""
                // label="Categories of interest"
              />
            </div>
            <StyledButton
              tw="self-start"
              loading={updateBoardMutation.isPending}
              cta
              type="submit"
            >
              Save
            </StyledButton>
          </div>
        </form>
      </OpaqueCard>
    </Contained>
  );
};

export default ConceptBoardForm;
