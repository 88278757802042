/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { useRef, useState } from "react";
import { useSelector } from "react-redux";

import { Add } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";

import _ from "lodash";

import {
  FixedHeightScrollLastChild,
  StyledButton,
} from "@components/StyledComponents";
import WithPermission, {
  permissions,
} from "@components/Utility/WithPermission";
import { Filters, useFilterParams } from "@features/filters";
import {
  ItemCatalogView,
  ItemModalPortal,
  ItemViewButtons,
  usePaginatedItemsQuery,
} from "@features/items";
import {
  AddToCartButton,
  VariantSelectionModal,
  useAddToCart,
  useDraftOrderSetQuery,
  useDraftOrderSetsQuery,
} from "@features/ordering";
import { CreateInventoryPurchaseOrderModal } from "@features/purchaseOrders";
import DocTitle from "@utility/DocTitle";

import FourOhFour from "./FourOhFour";

const when = (condition, value) => (condition ? [value] : []);

const useInStockFilter = () => {
  const territoryId: string = useSelector(
    (state: any) => state.currentUser.currentTerritoryId
  );
  const allocationLocation = useSelector(
    (state: any) => state.currentUser.organization.allocationLocation
  );
  if (allocationLocation === "territory")
    return { territoryHasInventory: territoryId };
  if (allocationLocation === "user") return { userHasInventory: true };
  return { hasInventory: true };
};

const useFilteredItems = (orderType: string, filters: Record<string, any>) => {
  const { currentTerritoryId, currentChannelId } = useSelector(
    (state: any) => state.currentUser
  );
  const inStockFilter = useInStockFilter();
  return usePaginatedItemsQuery({
    filter: {
      isActive: true,
      isOrderable: true,
      orderType,
      territoryIds: [currentTerritoryId],
      channelIds: currentChannelId && [currentChannelId],
      query: filters.q,
      favorites: filters.favorites,
      programIds: filters.programs,
      groupIds: filters.groups && Object.values(filters.groups).flat(),
      ...(filters.inStock && inStockFilter),
    },
  });
};

const PlaceOrder = ({
  orderType,
}: {
  orderType: "inventory" | "on-demand";
}) => {
  const [filterParams] = useFilterParams();
  const productView: "table" | "grid" = useSelector(
    (state: any) => state.globalState.productView
  );

  const orderSetIdRef = useRef<string | null>(null);

  const [inventoryPOItemId, setInventoryPOItemId] = useState<null | string>(
    null
  );

  const {
    variantSelectionItem,
    setVariantSelectionItem,
    addItemToOrderSet,
    addVariantsToOrderSet,
  } = useAddToCart(orderType, orderSetIdRef.current);

  const { isLoading: isLoadingOrderSets } = useDraftOrderSetsQuery();
  const { data: orderSet } = useDraftOrderSetQuery(orderType);
  orderSetIdRef.current = orderSet?.id ?? null;

  const orderSetVariants = orderSet?.orderSetVariants ?? [];

  const { data: items, ...tableProps } = useFilteredItems(
    orderType,
    filterParams
  );

  if (!["inventory", "on-demand"].includes(orderType)) {
    return <FourOhFour />;
  }

  return (
    <>
      {inventoryPOItemId && (
        <CreateInventoryPurchaseOrderModal
          itemId={inventoryPOItemId}
          handleClose={() => setInventoryPOItemId(null)}
        />
      )}
      {variantSelectionItem && (
        <VariantSelectionModal
          item={variantSelectionItem}
          onClose={() => setVariantSelectionItem(null)}
          {...{
            orderType,
            orderSetVariants,
            createOrUpdateOrderSet: addVariantsToOrderSet,
          }}
        />
      )}
      <DocTitle
        title={`${orderType === "inventory" ? "Inventory" : "On-Demand"} Order`}
      />
      <FixedHeightScrollLastChild>
        <div tw="flex flex-wrap justify-between items-center">
          <h2 tw="text-2xl text-neutral-600 font-bold mb-2">
            {orderType === "inventory"
              ? "Place an Inventory Order"
              : "Place an On-Demand Order"}
          </h2>
          <div tw="flex gap-2">
            <StyledButton
              cta
              disabled={!orderSet}
              loading={isLoadingOrderSets}
              to={`/order-sets/${orderSet?.id}`}
            >
              VIEW ORDER
            </StyledButton>
          </div>
        </div>
        <div tw="flex gap-2 items-start justify-between">
          <Filters
            searchTitle="Search Items"
            slots={[
              "favorites",
              ...when(orderType === "inventory", "inStock"),
              "groups",
              "programs",
            ]}
            {...(orderType === "inventory" && {
              defaultValues: { inStock: true },
            })}
          />
          <ItemViewButtons />
        </div>
        <ItemCatalogView
          rows={items}
          query={filterParams.q}
          rowActions={(item) => (
            <div
              css={[
                tw`flex items-center gap-2`,
                productView === "table" && tw`flex-row-reverse`,
              ]}
            >
              {orderType === "inventory" && (
                <WithPermission allow={permissions.createPurchaseOrder}>
                  <Tooltip title="Restock Inventory">
                    <IconButton
                      size="small"
                      edge="end"
                      css={[
                        tw`text-primary-600 bg-primary-50! hover:(bg-primary-100/50 text-primary-800)`,
                        productView === "grid" &&
                          tw`transition-opacity opacity-0 group-hover:opacity-100`,
                      ]}
                      onClick={() => setInventoryPOItemId(item.id)}
                    >
                      <Add />
                    </IconButton>
                  </Tooltip>
                </WithPermission>
              )}

              <AddToCartButton
                item={item}
                orderSetVariants={orderSet?.orderSetVariants ?? []}
                addToOrderSet={addItemToOrderSet}
              />
            </div>
          )}
          {...tableProps}
        />
        <ItemModalPortal>
          {(item) => (
            <div tw="flex items-baseline gap-3">
              <div tw="text-sm text-neutral-500">
                {_.startCase(item.orderType)} Order
              </div>
              <AddToCartButton
                item={item}
                orderSetVariants={orderSet?.orderSetVariants ?? []}
                addToOrderSet={addItemToOrderSet}
                variant="contained"
                tw="min-w-24"
              />
            </div>
          )}
        </ItemModalPortal>
      </FixedHeightScrollLastChild>
    </>
  );
};

export default PlaceOrder;
