/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { useEffect, useState } from "react";

import {
  Button,
  CircularProgress,
  ClickAwayListener,
  Skeleton,
} from "@mui/material";

import { useBudgetQuery } from "@features/budgets";
import { FaIcon, PaperTooltip } from "@features/ui";
import { formatMoneyString } from "@utility/utilityFunctions";
import { StyledFormLabel } from "@utils/forms/ControlledInputs";

import { useCurrentOrderSet } from "../CurrentOrderSetContext";
import BudgetImpactBreakdown from "./BudgetImpactBreakDown";
import BudgetSelectModal from "./BudgetSelectModal";

const AnimatedMoney = ({ target }: { target: number }) => {
  const [value, setValue] = useState(target);
  const [prevTarget, setPrevTarget] = useState(target);

  useEffect(() => {
    if (target === prevTarget) return;

    const startValue = prevTarget;
    const diff = target - startValue;
    const duration = 150; // total animation frames
    let frame = 0;

    const easeOutCubic = (x: number) => 1 - Math.pow(1 - x, 3);

    const interval = setInterval(() => {
      frame++;
      const progress = frame / duration;
      const easedProgress = easeOutCubic(progress);

      setValue(startValue + diff * easedProgress);

      if (frame >= duration) {
        clearInterval(interval);
        setValue(target);
        setPrevTarget(target);
      }
    }, 1); // run every millisecond for smooth animation

    return () => clearInterval(interval);
  }, [target, prevTarget]);

  return <span>{formatMoneyString(Math.round(value * 100) / 100)}</span>;
};

const BudgetInfo = ({
  budgetId,
  handleBudgetSelectOpen,
}: {
  budgetId: number | string;
  handleBudgetSelectOpen: () => void;
}) => {
  const [open, setOpen] = useState(false);
  const {
    data: budget,
    isLoading: isBudgetLoading,
    isFetching,
    error,
  } = useBudgetQuery(budgetId);

  if (isBudgetLoading) {
    return <Skeleton height={31} width={120} variant="rounded" />;
  }

  if (error || !budget) {
    return <div tw="text-red-500">Error loading budget</div>;
  }

  const isNegative = +budget.pendingBalance < 0;

  return (
    <PaperTooltip
      slotProps={{
        popper: { disablePortal: true },
        tooltip: {
          sx: { maxWidth: "none" },
          onClick: (e) => e.stopPropagation(),
        },
      }}
      open={open}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      placement="bottom-end"
      title={
        <div tw="space-y-3 min-w-80 max-w-sm">
          <div tw="flex justify-between items-baseline">
            <h5 tw="font-normal text-primary-600 text-base">Budget Impact</h5>
            <Button
              variant="text"
              color="secondary"
              size="small"
              onClick={handleBudgetSelectOpen}
              endIcon={<FaIcon icon="edit" tw="text-sm!" />}
            >
              Change Budget
            </Button>
          </div>
          <h3 tw="text-lg font-medium -mt-0! mb-2! w-10/12 leading-tight [text-wrap:pretty]">
            {budget.name}
          </h3>
          <BudgetImpactBreakdown budget={budget} />
        </div>
      }
    >
      <div tw="relative h-full" tabIndex={0}>
        <StyledFormLabel tw="w-auto absolute bottom-full right-0">
          Budget Impact
        </StyledFormLabel>
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <button
            css={[
              tw`flex min-w-[148px]! transition-colors items-center gap-2 px-2 py-1 text-base justify-between bg-white border rounded shadow-md text-primary-800 border-primary-600 hover:bg-primary-50`,
              isNegative &&
                tw`text-red-800 border-red-200 bg-red-50 hover:bg-red-100`,
            ]}
            onClick={() => setOpen(!open)}
          >
            <CircularProgress
              size={16}
              css={[
                tw`transition-opacity duration-300 opacity-0`,
                isFetching && tw`opacity-100`,
              ]}
            />
            <AnimatedMoney target={+budget.pendingBalance} />
          </button>
        </ClickAwayListener>
      </div>
    </PaperTooltip>
  );
};

const BudgetImpact = () => {
  const {
    orderSet: { budgetId },
  } = useCurrentOrderSet();
  const [isBudgetSelectOpen, setBudgetSelectOpen] = useState(false);

  const handleBudgetSelectClose = () => {
    setBudgetSelectOpen(false);
  };
  return (
    <div tw="flex">
      {!budgetId && (
        <Button
          variant="contained"
          color="secondary"
          size="small"
          onClick={() => setBudgetSelectOpen(true)}
          startIcon={<FaIcon icon="add" />}
        >
          Budget Impact
        </Button>
      )}
      {budgetId && (
        <BudgetInfo
          budgetId={budgetId}
          handleBudgetSelectOpen={() => setBudgetSelectOpen(true)}
        />
      )}
      {isBudgetSelectOpen && (
        <BudgetSelectModal
          open={isBudgetSelectOpen}
          handleClose={handleBudgetSelectClose}
        />
      )}
    </div>
  );
};

export default BudgetImpact;
