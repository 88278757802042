import { createContext, useContext } from "react";

import { ResourceError } from "@features/errors";
import { useItemQuery } from "@features/items";
import { Item } from "@models/Item";

import { ItemModalSkeleton } from "./ItemModalSkeleton";

const ItemContext = createContext<{ item: Item } | null>(null);

export const ItemProvider = ({
  itemId,
  children,
}: {
  itemId: string;
  children: React.ReactNode;
}) => {
  const { data: item, error, isPending } = useItemQuery(itemId);

  if (error) {
    return <ResourceError error={error} />;
  }

  if (isPending) {
    return <ItemModalSkeleton />;
  }

  return (
    <ItemContext.Provider value={{ item }}>{children}</ItemContext.Provider>
  );
};

export const useItem = () => {
  const context = useContext(ItemContext);
  if (!context) {
    throw new Error("useItem must be used within an ItemProvider");
  }
  return context;
};
