/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useParams } from "react-router";

import { CircularProgress } from "@mui/material";

import { Contained, RecessCard } from "@components/StyledComponents";
import { ItemAdminForm, useItemQuery } from "@features/items";
import { Title } from "@features/ui";
import DocTitle from "@utility/DocTitle";

import ErrorPage from "./ErrorPage";

const AdminItem = () => {
  const { id } = useParams();
  const { data: itemData, error } = useItemQuery(id);

  if (error) {
    return <ErrorPage error={error} />;
  }

  if (!itemData) {
    return (
      <RecessCard>
        <CircularProgress />
      </RecessCard>
    );
  }
  return (
    <Contained tw="max-w-screen-xl mx-auto">
      <DocTitle title={`${itemData.name} | Item Admin`} />
      <div tw="lg:col-span-2 flex flex-wrap justify-between items-center gap-4 mb-4">
        <Title backUrl="/admin/items">{itemData.name}</Title>
      </div>
      <ItemAdminForm item={itemData} />
    </Contained>
  );
};

export default AdminItem;
