/** @jsxImportSource @emotion/react */
import "twin.macro";

import React from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";

import { Button, Fade, InputBase } from "@mui/material";

import { useCreateConceptMutation } from "@features/concepts";
import permissions from "@utils/permissions";
import useRoleIs from "@utils/useRoleIs";

import { Card } from "./ConceptCard";

const NewConceptCard = () => {
  const { conceptId } = useParams();

  const isAdmin = useRoleIs()(permissions.admin);
  const createConceptMutation = useCreateConceptMutation();
  const {
    register,
    getValues,
    formState: { isDirty },
    reset,
  } = useForm({
    defaultValues: {
      description: "",
    },
  });

  const handleSave = () => {
    const { description } = getValues();
    if (!description) return;
    createConceptMutation.mutate({ description, parentConceptId: conceptId });
    setTimeout(() => reset(), 300);
  };
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter" && (e.metaKey || e.ctrlKey)) {
      handleSave();
    }
  };

  let modifierKeyPrefix = "ctrl"; // control key
  if (
    navigator.platform.indexOf("Mac") === 0 ||
    navigator.platform === "iPhone"
  ) {
    modifierKeyPrefix = "⌘"; // command key
  }

  return (
    <Fade in timeout={500}>
      <Card className="group" tw="border-none focus-within:shadow-xl">
        {/* <div tw="block absolute -z-0 -inset-8 from-white to-primary-lighter via-white bg-gradient-to-tr via-40% transition-transform duration-500 group-hover:(translate-x-1/4 -translate-y-1/4)" /> */}
        <div tw="absolute top-0 w-full border-t-4 border-primary-600 group-hover:border-primary-500 group-focus-within:border-primary-500" />

        <div tw="pt-4 px-6">
          <div tw="text-primary-600 uppercase text-sm">New Concept</div>
          <InputBase
            placeholder="Start Typing..."
            fullWidth
            multiline
            minRows={3}
            onKeyDown={handleKeyDown}
            {...register("description")}
          />
        </div>
        {!isDirty && isAdmin && (
          <div tw="text-center p-1">
            <span tw="text-neutral-500">or </span>

            <a tw="text-primary-800" href="#">
              Import from brandhub
            </a>
          </div>
        )}
        {isDirty && (
          <Button
            tw="w-full font-normal p-1 bg-primary-600! text-white rounded-none"
            onClick={handleSave}
          >
            PRESS {modifierKeyPrefix} + ENTER to SAVE
          </Button>
        )}
      </Card>
    </Fade>
  );
};

export default NewConceptCard;
