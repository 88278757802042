/** @jsxImportSource @emotion/react */
import "twin.macro";

import { Link } from "react-router";

import { Button } from "@mui/material";

import _ from "lodash";

import { useCurrentOrderSet } from "../CurrentOrderSetContext";
import useDeleteOrderSetMutation from "../data/mutations/useDeleteOrderSetMutation";
import OrderSetApprovalActions from "../orderSetApproval/OrderSetApprovalActions";
import useCanEditOrderSet from "../useCanEditOrderSet";
import OrderSetCostOverview from "./OrderSetCostOverview";

const OrderSetSummaryActionPanel = () => {
  const { orderSetVariants, orderSet } = useCurrentOrderSet();
  const deleteOrderSet = useDeleteOrderSetMutation();

  const canEdit = useCanEditOrderSet();

  const countByItemIds = _(orderSetVariants)
    .groupBy("variant.item.id")
    .mapValues((v) => _.sumBy(v, "totalQty"))
    .value();

  const allItemsMeetMoq =
    orderSet.type !== "on-demand" || // only check minimum order quantity for on-demand
    orderSetVariants.every(
      (ov) =>
        countByItemIds[ov.variant.item.id] === 0 || // 0 counts will be removed, so they can be ignored
        countByItemIds[ov.variant.item.id] >=
          ov.variant.item.minimumOrderQuantity
    );

  return (
    <>
      <OrderSetCostOverview />
      <div tw="mt-4">
        {orderSet.status === "draft" && (
          <div>
            <Button
              variant="contained"
              fullWidth
              size="large"
              component={Link}
              to="../checkout"
              disabled={!allItemsMeetMoq}
            >
              Proceed to Checkout
            </Button>

            {!allItemsMeetMoq && (
              <p tw="text-sm text-red-700 mt-2">
                Some items do not meet minimum order quantity. Please fix before
                proceeding to checkout.
              </p>
            )}
          </div>
        )}
        <OrderSetApprovalActions />
      </div>
      {canEdit && (
        <div tw="mt-4 flex justify-end">
          <Button
            variant="danger"
            onClick={() =>
              window.confirm(
                "Are you sure you want to delete this order set?"
              ) && deleteOrderSet.mutate()
            }
          >
            Delete Order Set
          </Button>
        </div>
      )}
    </>
  );
};

export default OrderSetSummaryActionPanel;
