/** @jsxImportSource @emotion/react */
import "twin.macro";
import tw from "twin.macro";

import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";

import { ExpandMoreRounded } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  LinearProgress,
  Typography,
} from "@mui/material";

import { format } from "date-fns";

import { OpaqueCard } from "@components/StyledComponents";
import { ItemPreviewImageButton } from "@features/items";
import { Item } from "@models/Item";
import { useSetLocation } from "@services/reactRouterDom";
import { formats } from "@utility/dateTime";

import useBeaconItemLookup from "../useBeaconItemLookup";
import useFilteredBeaconPings from "../useFilteredBeaconPings";

const ScrollIntoView = () => {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [ref]);
  return <div ref={ref} />;
};

const PingItemPreview = ({
  variantSku,
  item,
}: {
  variantSku: string;
  item?: Item;
}) => {
  if (!item) return null;
  const variant = item.variants.find((v) => v.variantSku === variantSku)!;
  return (
    <ItemPreviewImageButton
      item={item}
      imageId={variant?.imageId}
      tw="w-16 h-16"
    />
  );
};

const BeaconPingsList = () => {
  const { hash } = useLocation();
  const setLocation = useSetLocation();
  const items = useBeaconItemLookup();
  const {
    data: beacons,
    isLoading,
    isFetching,
    isPlaceholderData,
  } = useFilteredBeaconPings();

  const [expanded, setExpanded] = useState(hash);

  useEffect(() => {
    setExpanded(hash);
  }, [hash]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    if (isExpanded) {
      setLocation({ hash: panel }, { replace: true });
    }
  };

  return (
    <OpaqueCard
      css={[
        tw`relative p-0 overflow-y-auto transition-opacity`,
        isPlaceholderData && tw`opacity-50`,
      ]}
    >
      {beacons.length === 0 && !isLoading && (
        <p tw="p-6 text-xl text-neutral-500">
          No Pings Recorded for the selected filters.
        </p>
      )}
      <div tw="p-2 pb-8">
        {isLoading && <CircularProgress size={24} tw="p-4" />}
        {beacons.map((beacon, i) => (
          <Accordion
            key={i}
            expanded={expanded === `#${beacon.id}`}
            onChange={handleChange(`#${beacon.id}`)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreRounded />}
              aria-controls={`panel${i}-content`}
              id={`panel${i}-header`}
              css={{
                "&": [
                  tw`flex-row-reverse items-start gap-2 px-3 transition rounded-lg hover:bg-neutral-100 text-neutral-700`,
                  hash === `#${beacon.id}` && tw`border-2 border-primary-100`,
                ],
                ".MuiAccordionSummary-expandIconWrapper": tw`mt-3`,
              }}
            >
              <div tw="flex  flex-row-reverse items-start gap-4 w-full">
                <PingItemPreview
                  variantSku={beacon.variantSku}
                  item={items.get(beacon.itemId.toString())}
                />
                <div tw="w-full">
                  <div tw="flex items-center justify-between gap-2">
                    <h3 tw="text-base font-bold leading-tight">
                      {beacon.storeName ??
                        "UNKNOWN ADDRESS, SEE MAP FOR DETAILS"}
                    </h3>
                    <span tw="font-normal text-primary-900 bg-primary-100 text-sm px-2 rounded-full">
                      {beacon.variantSku}
                    </span>
                  </div>

                  <div tw="2xl:(flex-row justify-between items-baseline gap-3)">
                    <div tw="text-sm text-neutral-500">
                      {beacon.storeStreetAddress
                        ? `${beacon.storeStreetAddress}, ${beacon.storeCity} ${
                            beacon.storeStateCode
                          }, ${beacon.storeZip?.slice(0, 5)}`
                        : `LAT/LONG: ${beacon.lat.toFixed(
                            4
                          )}, ${beacon.lng.toFixed(4)}`}
                    </div>
                    <div tw="text-sm text-primary-600">
                      Last pinged{" "}
                      {format(
                        new Date(beacon.timestamp),
                        formats.keyboardDateTime
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              {expanded === `#${beacon.id}` && <ScrollIntoView />}
              <Typography component="div" tw="text-sm text-neutral-500">
                {/* Check if the first and second stores returned are the same */}
                {beacon.firstStoreStreetAddress + beacon.firstStoreZip !==
                beacon.storeStreetAddress + beacon.storeZip ? (
                  <div tw="flex flex-col">
                    <div tw="text-neutral-500 italic">Previously at:</div>
                    <h3 tw="text-base font-bold leading-tight">
                      {beacon.firstStoreName}
                    </h3>
                    <div tw="2xl:(flex-row justify-between items-baseline gap-3)">
                      <div tw="text-sm text-neutral-500">
                        {`${beacon.firstStoreStreetAddress}, ${
                          beacon.firstStoreCity
                        } ${
                          beacon.firstStoreStateCode
                        }, ${beacon.firstStoreZip?.slice(0, 5)}`}
                      </div>
                      <div tw="text-sm text-primary-600">
                        First pinged{" "}
                        {format(
                          new Date(beacon.firstTimestamp),
                          formats.keyboardDateTime
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <>No additional details available</>
                )}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
      {isFetching && <LinearProgress tw="absolute bottom-0 w-full z-10" />}
    </OpaqueCard>
  );
};

export default BeaconPingsList;
