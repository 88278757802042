/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useState } from "react";
import { useSelector } from "react-redux";

import CancelIcon from "@mui/icons-material/CloseRounded";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Skeleton,
} from "@mui/material";

import _ from "lodash";
import PropTypes from "prop-types";

import ReactQueryTable from "@components/Table/ReactQueryTable";
import { usePaginatedBudgetsQuery } from "@features/budgets";
import { Filters } from "@features/filters";
import {
  useCurrentOrderSet,
  useSetOrderSetBudgetMutation,
} from "@features/ordering";
import { LoadingButton } from "@features/ui";
import { Budget } from "@models/Budget";
import { formatMoneyString } from "@utility/utilityFunctions";

import useCalculateOrderSetBudgetImpact from "./useCalculateOrderSetBudgetImpact";

const BudgetSelectModal = ({ open, handleClose }) => {
  const { orderSet } = useCurrentOrderSet();

  const osImpact = useCalculateOrderSetBudgetImpact();

  const setBudget = useSetOrderSetBudgetMutation();

  const {
    id: userId,
    currentTerritoryId,
    organization: { budgetLocation },
  } = useSelector((state: any) => state.currentUser);

  const [selectedBudget, setSelectedBudget] = useState<number | null>(
    orderSet.budgetId
  );
  const [filters, setFilters] = useState<Record<string, any>>({});

  const handleSetBudget = (budgetId: number | null) => {
    setBudget.mutate(budgetId, {
      onSuccess: () => handleClose(),
      onError: () => setSelectedBudget(orderSet.budgetId),
    });
  };

  const { data: budgets, ...tableProps } = usePaginatedBudgetsQuery({
    filter: {
      ...(filters.q && { name: filters.q }),
      isActive: true,
      ...(budgetLocation === "user" && { userIds: [userId] }),
      ...(budgetLocation === "territory" && {
        territoryIds: [currentTerritoryId],
      }),
    },
  });

  const handleClick = (budget: Budget) => {
    if (selectedBudget === +budget.id) {
      setSelectedBudget(null);
    } else {
      setSelectedBudget(+budget.id);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle tw="flex justify-between items-center">
        Select a budget for this order
        <IconButton onClick={handleClose} edge="end">
          <CancelIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Filters slots={[]} filterState={filters} setFilterState={setFilters} />
        <ReactQueryTable
          {...tableProps}
          rows={budgets}
          columns={[
            {
              id: "name",
              label: "Name",
              render: (name, budget) => (
                <div tw="flex gap-2 items-center">
                  <Checkbox
                    tw="-my-3"
                    edge="start"
                    checked={selectedBudget === +budget.id}
                  />
                  {name}
                </div>
              ),
            },
            {
              id: "_available",
              label: "Amount Available",
              align: "right",
              render: (_, budget) =>
                tableProps.isFetching ? (
                  <Skeleton width={56} tw="float-right" />
                ) : (
                  formatMoneyString(
                    +budget.id === orderSet.budgetId
                      ? // in that case, pending balance is already taking into account the os total,
                        // add it back to get the value before the os was added
                        +budget.pendingBalance + osImpact
                      : budget.pendingBalance
                  )
                ),
            },
          ]}
          onRowClick={(budget) => handleClick(budget)}
          maxHeight={400}
        />
      </DialogContent>
      <DialogActions>
        <LoadingButton variant="outlined" onClick={handleClose}>
          Cancel
        </LoadingButton>
        <LoadingButton
          onClick={() => handleSetBudget(selectedBudget)}
          variant="contained"
          loading={setBudget.isPending}
        >
          Done
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

BudgetSelectModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default BudgetSelectModal;
