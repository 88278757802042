/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import React, { useEffect, useState } from "react";

import { KeyboardArrowDownRounded } from "@mui/icons-material";
import {
  Autocomplete,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers";

import { utcDate } from "@utility/utilityFunctions";

import { StyledFormLabel, StyledSelectField } from "./ControlledInputs";

export const TextInput = React.forwardRef<HTMLDivElement, TextFieldProps>(
  (props, ref) => (
    <TextField ref={ref} tw="bg-white" size="small" fullWidth {...props} />
  )
);

export const DatePicker = React.forwardRef<HTMLDivElement, any>(
  ({ onChange, value, className, label, ...props }, ref) => {
    const [date, setDate] = useState<Date | null>(value && utcDate(value));

    useEffect(() => {
      if (value) setDate(utcDate(value));
    }, [value]);

    return (
      <FormControl fullWidth className={className}>
        {label && <StyledFormLabel id={props.name}>{label}</StyledFormLabel>}
        <MuiDatePicker
          ref={ref}
          minDate={utcDate("2016-01-01")}
          maxDate={utcDate("2031-01-01")}
          slotProps={{
            ...props.slotProps,
            popper: props.slotProps?.popper,
            field: {
              size: "small",
              ...props.slotProps?.field,
              onBlur: () => {
                props.slotProps?.field?.onBlur?.(date);
                onChange?.(date);
              },
            },
          }}
          value={date}
          onAccept={onChange}
          onChange={(value: Date | null, context) => {
            if (!context.validationError) {
              setDate(value);
            }
          }}
          {...props}
        />
      </FormControl>
    );
  }
);

export const StyledSelect = React.forwardRef<
  HTMLInputElement,
  SelectProps & { helperText?: string | null }
>(({ helperText, className, ...props }, ref) => (
  <FormControl fullWidth className={className}>
    {props.label && (
      <StyledFormLabel error={props.error}>{props.label}</StyledFormLabel>
    )}
    <StyledSelectField
      inputRef={ref}
      IconComponent={KeyboardArrowDownRounded}
      {...props}
    />
    {helperText && (
      <FormHelperText tw="ml-0" error={props.error}>
        {helperText}
      </FormHelperText>
    )}
  </FormControl>
));

type InputOption = {
  id: string | number;
  name: string;
  [key: string]: any;
};

type SelectInputProps = {
  options: InputOption[];
  value: number | string;
  label?: string;
  helperText?: string;
  onChange: (...any) => any;
  getOptionDisabled?: (option: InputOption) => boolean;
  [key: string]: any;
};
export const SelectInput = React.forwardRef<HTMLDivElement, SelectInputProps>(
  (
    { label, options, helperText, className, getOptionDisabled, ...props },
    ref
  ) => (
    <FormControl size="small" className={className}>
      <InputLabel>{label}</InputLabel>
      <Select ref={ref} label={label} {...props}>
        {options.map((option) => (
          <MenuItem
            key={option.id}
            value={option.id}
            disabled={getOptionDisabled ? getOptionDisabled(option) : false}
          >
            {option.name}
          </MenuItem>
        ))}
      </Select>
      {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
);

export const StyledSelectInput = React.forwardRef<
  HTMLDivElement,
  SelectInputProps
>(
  (
    {
      label,
      options,
      helperText,
      className,
      getOptionDisabled,
      error,
      disabled,
      ...props
    },
    ref
  ) => (
    <FormControl
      className={className}
      {...{ error: !!error, disabled }}
      size="small"
      fullWidth
      ref={ref}
    >
      <StyledFormLabel id={props.name}>{label}</StyledFormLabel>
      <StyledSelectField
        className={className}
        IconComponent={KeyboardArrowDownRounded}
        {...props}
        MenuProps={{
          ...props.MenuProps,
          PaperProps: { sx: { maxHeight: 400 } },
        }}
      >
        {options.map((o, i) => (
          <MenuItem
            key={`${o.id}-${i}`}
            value={o.id}
            disabled={o.disabled || getOptionDisabled?.(o)}
          >
            {o.name}
          </MenuItem>
        ))}
      </StyledSelectField>
      {(error || helperText) && (
        <FormHelperText>{error?.message ?? helperText}</FormHelperText>
      )}
    </FormControl>
  )
);

type AutocompleteInputProps = {
  options: InputOption[];
  value: InputOption;
  label?: string;
  helperText?: string;
  onChange: (...any) => any;
  [key: string]: any;
};
export const AutocompleteInput = React.forwardRef<
  HTMLDivElement,
  AutocompleteInputProps
>(({ label, options, onChange, error, helperText, ...props }, ref) => {
  // Create a lookup for faster fetching
  const optionsLookup = options.reduce((obj, a) => {
    obj[a.id] = a;
    return obj;
  }, {});

  const getOpObj = (option) => {
    if (!option.id) option = optionsLookup[option];
    return option;
  };

  return (
    <Autocomplete
      ref={ref}
      size="small"
      options={options}
      isOptionEqualToValue={(option: any, value) =>
        option?.id === getOpObj(value)?.id
      }
      getOptionLabel={(option) =>
        getOpObj(option)?.label || getOpObj(option)?.name || ""
      }
      renderInput={(params) => (
        <TextInput
          {...params}
          label={label}
          error={!!error}
          helperText={error?.message || helperText}
        />
      )}
      onChange={(_, value) => onChange(value)}
      {...props}
    />
  );
});

export const InputLoading = tw.div`h-10 rounded-md bg-neutral-100 animate-pulse`;
