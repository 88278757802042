/** @jsxImportSource @emotion/react */
import "twin.macro";

import { Link } from "react-router";

import { CloseRounded, OpenInFull } from "@mui/icons-material";
import { IconButton, Skeleton, Tooltip } from "@mui/material";

import { PageTitle } from "@components/StyledComponents";
import { ResourceError } from "@features/errors";
import { UserForm, useUserQuery } from "@features/users";

const UserSkeleton = () => {
  return (
    <div tw="grid gap-x-4 gap-y-6">
      <Skeleton variant="rounded" height={300} tw="rounded-xl" />
      <Skeleton variant="rounded" height={200} tw="rounded-xl" />
    </div>
  );
};

const UserSidebarContent = ({
  id,
  handleClose,
}: {
  id: string;
  handleClose: () => void;
}) => {
  const isNew = !id || id === "new";
  const { data: user, error } = useUserQuery(isNew ? null : id);

  const isLoading = !isNew && !user;

  return (
    <div>
      <div tw="px-6 sticky top-0 pt-6 pb-3 bg-white -mb-3 z-10 flex justify-between items-center">
        <PageTitle>
          {error && "Error"}
          {!error &&
            (isNew ? "New User" : user?.name || <Skeleton tw="w-48" />)}
        </PageTitle>

        <div tw="top-2 z-20">
          <Tooltip title="Full-page view" placement="top">
            <IconButton component={Link} to={`/admin/users/${id}`}>
              <OpenInFull tw="text-xl" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Close" placement="top">
            <IconButton onClick={handleClose} edge="end">
              <CloseRounded />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      {error && <ResourceError error={error} />}
      {!error && isLoading && <UserSkeleton />}
      {!error && !isLoading && <UserForm user={user} id={isNew ? null : id} />}
    </div>
  );
};

export default UserSidebarContent;
