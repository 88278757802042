/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router";

import { AddCircle, Cancel } from "@mui/icons-material";
import { Typography } from "@mui/material";

import { useFilterParams } from "@features/filters";
import { ItemCatalogTable, usePaginatedItemsQuery } from "@features/items";
import { setItemSelection } from "@redux/slices/items/itemSlice";
import { updateFavoriteItems } from "@redux/slices/user/currentUserSlice";

import {
  FixedHeightScrollLastChild,
  StyledButton,
} from "../../StyledComponents";

const FavoriteItems = () => {
  const dispatch = useDispatch();
  const [filterParams] = useFilterParams();

  const { favoriteItemIds } = useSelector((state: any) => state.currentUser);
  const selectedItems = useSelector((state: any) => state.items.selectedItems);
  const { data, ...tableProps } = usePaginatedItemsQuery({
    filter: { favorites: true },
    sort: filterParams.sort,
  });

  const handleRemoveFavorites = () => {
    const newFavoriteItems = favoriteItemIds.filter(
      (id) => !selectedItems.includes(id.toString())
    );
    dispatch(updateFavoriteItems(newFavoriteItems));
    dispatch(setItemSelection({ items: [] }));
  };

  useEffect(() => {
    dispatch(setItemSelection({ items: [] }));
  }, [dispatch]);

  return (
    <FixedHeightScrollLastChild>
      <header tw="flex justify-between items-center flex-wrap">
        <Typography tw="text-2xl font-bold">Favorite Items</Typography>
        <div tw="flex items-center gap-3">
          <StyledButton
            outlined
            startIcon={<Cancel />}
            onClick={handleRemoveFavorites}
            disabled={selectedItems.length === 0}
          >
            REMOVE ITEMS
          </StyledButton>
          <StyledButton
            cta
            startIcon={<AddCircle />}
            component={Link}
            to="/items"
          >
            ADD ITEMS
          </StyledButton>
        </div>
      </header>
      <ItemCatalogTable showCheckbox {...tableProps} rows={data ?? []} />
    </FixedHeightScrollLastChild>
  );
};

export default FavoriteItems;
