/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";

import {
  GoogleMap,
  InfoWindow,
  LoadScript,
  Marker,
  MarkerClusterer,
} from "@react-google-maps/api";

import { useFilterParams } from "@features/filters";
import { useSetLocation } from "@services/reactRouterDom";
import stateCenterZoomMap from "@utility/stateCenterZoomMap.js";

import useFilteredBeaconPings from "../useFilteredBeaconPings";

const BEACON_FOCUS_ZOOM = 17;

const mapStyles: google.maps.MapTypeStyle[] = [
  {
    featureType: "poi",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "road",
    elementType: "labels.icon",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "transit",
    stylers: [{ visibility: "off" }],
  },
];

const icons = [
  { color: "red", url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png" },
  {
    color: "lightblue",
    url: "http://maps.google.com/mapfiles/ms/icons/ltblue-dot.png",
  },
  {
    color: "green",
    url: "http://maps.google.com/mapfiles/ms/icons/green-dot.png",
  },
  {
    color: "pink",
    url: "http://maps.google.com/mapfiles/ms/icons/pink-dot.png",
  },
  {
    color: "blue",
    url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
  },
  {
    color: "orange",
    url: "http://maps.google.com/mapfiles/ms/icons/orange-dot.png",
  },
  {
    color: "purple",
    url: "http://maps.google.com/mapfiles/ms/icons/purple-dot.png",
  },
  {
    color: "yellow",
    url: "http://maps.google.com/mapfiles/ms/icons/yellow-dot.png",
  },
];

const BeaconMap = () => {
  // const { programId, state, variantSku } = useParams();
  const [{ state = "national" }] = useFilterParams();
  const [map, setMap] = useState<null | google.maps.Map>(null);
  const { hash } = useLocation();
  const [activeBeacon, setActiveBeacon] = useState<string | null>(null);
  const setLocation = useSetLocation();

  const { data: beacons } = useFilteredBeaconPings();

  const colorMap = useRef(new Map());
  const variantIdx = useRef(0);

  const getVariantColor = (variantSku: string) => {
    if (!colorMap.current.has(variantSku)) {
      colorMap.current.set(variantSku, icons[variantIdx.current].url);
      variantIdx.current = (variantIdx.current + 1) % icons.length;
    }
    return colorMap.current.get(variantSku);
  };

  // // Load variants
  // const { variants: bVariants } = useBeaconProgram(
  //   {
  //     programId,
  //     state,
  //   },
  //   "variants"
  // );

  // Sets which beacon we've currently clicked on to show it's id
  const handleActiveBeacon = (beacon) => {
    if (beacon.id === activeBeacon) {
      return;
    }
    setActiveBeacon(beacon.id);
    setLocation({ hash: `#${beacon.id}` }, { replace: true });
    map?.panTo({ lat: beacon.lat, lng: beacon.lng });
  };

  useEffect(() => {
    setActiveBeacon(null);
  }, [beacons]);
  // When the state (as in United states state) is changed, re-center and set zoom level.
  useEffect(() => {
    if (map && state) {
      map.setZoom(stateCenterZoomMap[state].zoom);
      map.panTo(stateCenterZoomMap[state].center);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    if (hash && map) {
      const hashBeaconId = hash.substring(1);
      if (hashBeaconId !== activeBeacon) {
        const selectedBeacon = beacons.find(({ id }) => id === hashBeaconId);
        if (!selectedBeacon) {
          return;
        }
        map.setZoom(BEACON_FOCUS_ZOOM);
        map.panTo({
          lat: selectedBeacon.lat,
          lng: selectedBeacon.lng,
        });

        setActiveBeacon(hashBeaconId);
      }
    } else {
      setActiveBeacon(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash]);

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY!}>
        <GoogleMap
          mapContainerStyle={{
            width: "100%",
            height: "100%",
            borderRadius: "10px",
          }}
          center={stateCenterZoomMap[state].center}
          zoom={stateCenterZoomMap[state].zoom}
          onLoad={(map) => setMap(map)}
          options={{
            styles: mapStyles,
            streetViewControl: false,
            mapTypeControl: false,
          }}
        >
          <MarkerClusterer>
            {(clusterer) => (
              <>
                {beacons.map((beacon) => (
                  <Marker
                    key={beacon.id}
                    position={{
                      lat: beacon.lat,
                      lng: beacon.lng,
                    }}
                    clusterer={clusterer}
                    onClick={() => handleActiveBeacon(beacon)}
                    icon={getVariantColor(beacon.variantSku)}
                    title={beacon.id}
                  >
                    {activeBeacon === beacon.id && (
                      <InfoWindow onCloseClick={() => setActiveBeacon(null)}>
                        <div tw="text-center">
                          {beacon.storeStreetAddress ? (
                            <>
                              <div tw="font-semibold">{beacon.storeName}</div>
                              <div>
                                {beacon.storeStreetAddress}, {beacon.storeCity},{" "}
                                {beacon.storeStateCode}
                              </div>
                            </>
                          ) : (
                            <>
                              <div tw="font-semibold">Unspecified Location</div>
                              <div>
                                {beacon.lat.toFixed(4)}, {beacon.lng.toFixed(4)}
                              </div>
                            </>
                          )}
                        </div>
                      </InfoWindow>
                    )}
                  </Marker>
                ))}
              </>
            )}
          </MarkerClusterer>
        </GoogleMap>
      </LoadScript>
    </div>
  );
};

export default BeaconMap;
