/** @jsxImportSource @emotion/react */
import "twin.macro";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router";

import CancelIcon from "@mui/icons-material/CloseRounded";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

import { OrganizationSelector } from "@features/users";
import {
  createOrUpdateSupplier,
  fetchSupplier,
  setHasUpdated,
} from "@redux/slices/suppliers/supplierSlice";

import { useInput } from "../../../hooks/inputs/useInput";
import SingleUserAutoComplete from "../../Utility/AutoCompleteFields/SingleUserAutoComplete";
import OptionTable from "../../Utility/OptionTable";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const SupplierModal = ({ open, type, id, handleClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    value: name,
    bind: bindName,
    setValue: setName,
    reset: resetName,
  } = useInput("");

  const [supplier, setSupplier] = useState(null);
  const [users, setUsers] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [isActive, setIsActive] = useState(true);
  const [localError, setLocalError] = useState(null);
  const [resetField, setResetField] = useState(false);

  const { isLoading, isUpdateLoading, hasUpdated, currentSupplier } =
    useSelector((state) => state.suppliers);

  const handleSubmit = () => {
    if (name.length === 0)
      setLocalError("You must fill out the suppliers name before submitting");
    else {
      setLocalError(null);
      dispatch(
        createOrUpdateSupplier(
          {
            name,
            isActive,
            users,
            organizations,
          },
          type === "edit" && id ? id : null
        )
      );
    }
  };

  const handleAddUser = (user) => {
    let currentUser = users.find((u) => u.id === user.id);
    setResetField(true);
    if (currentUser) return;
    setUsers(users.concat(user));
  };

  const handleRemoveUser = (id) => {
    setUsers(users.filter((u) => u.id !== id));
  };

  useEffect(() => {
    if (
      type === "edit" &&
      !isLoading &&
      currentSupplier &&
      (!supplier || supplier.id !== currentSupplier.id)
    ) {
      setSupplier({ ...currentSupplier });
      setOrganizations(currentSupplier.organizations?.map((o) => o.id) ?? []);
      setName(currentSupplier.name);
      setUsers(currentSupplier.users);
      setIsActive(currentSupplier.isActive);
    }
  }, [currentSupplier, isLoading, setName, supplier, type]);

  useEffect(() => {
    if (type === "edit" && id) {
      dispatch(fetchSupplier(id));
    }
    dispatch(setHasUpdated({ value: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.relativeContainer}>
      <Dialog
        open={open}
        onClose={() => {
          resetName();
          handleClose();
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          <Typography className={classes.headerText}>
            {type === "edit" ? `Editing ${name}` : "New Supplier"}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <IconButton
            className={classes.closeButton}
            onClick={() => {
              resetName();
              handleClose();
            }}
            size="large"
          >
            <CancelIcon fontSize="large" color="secondary" />
          </IconButton>
          <div className={classes.centeredModal}>
            {isLoading && (
              <>
                <br />
                <br />
                <CircularProgress color="secondary" />
                <br />
                <br />
              </>
            )}
            {!isLoading && !isUpdateLoading && !hasUpdated && (
              <>
                <TextField
                  size="small"
                  className={classes.settingsMargin}
                  variant="outlined"
                  color="secondary"
                  name="name"
                  type="text"
                  label="Name"
                  fullWidth
                  {...bindName}
                />
                <FormControlLabel
                  className={classes.settingsMargin}
                  control={
                    <Switch
                      checked={isActive}
                      onChange={() => setIsActive(!isActive)}
                      name="active-switch"
                    />
                  }
                  label={isActive ? "Active" : "Inactive"}
                />
                <OrganizationSelector
                  multiple
                  tw="w-full max-w-full"
                  value={organizations}
                  onChange={(e) => setOrganizations(e.target.value)}
                />
                <SingleUserAutoComplete
                  classes={classes}
                  handleChange={handleAddUser}
                  reset={resetField}
                  setReset={setResetField}
                  disabled={isUpdateLoading}
                  roles={["supplier"]}
                />
                <br />
                <OptionTable
                  headCells={[
                    {
                      id: "id",
                      label: "Id",
                      render: (id) => (
                        <Link tw="underline" to={`/admin/users/${id}`}>
                          {id}
                        </Link>
                      ),
                    },
                    {
                      id: "name",
                      label: "Name",
                    },
                  ]}
                  options={users}
                  handleRemove={handleRemoveUser}
                  isRemoveable
                />
                <br />
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleSubmit}
                  style={{ minWidth: "62px" }}
                >
                  {isUpdateLoading ? (
                    <CircularProgress color="secondary" />
                  ) : (
                    "SUBMIT"
                  )}
                </Button>
                {!isUpdateLoading && localError && (
                  <>
                    <br />
                    <Typography
                      className={classes.bodyText}
                      style={{ color: "#920000" }}
                    >
                      {localError}
                    </Typography>
                  </>
                )}
              </>
            )}
            {!isLoading && !isUpdateLoading && hasUpdated && (
              <>
                <br />
                <Typography className={classes.headerText}>
                  {type === "edit"
                    ? "Update Successful!"
                    : "Supplier Created Successfully!"}
                </Typography>
                <br />
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    resetName();
                    handleClose();
                  }}
                >
                  CLOSE
                </Button>
              </>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

SupplierModal.propTypes = {
  open: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  id: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
};

export default SupplierModal;
