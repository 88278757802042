/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import { useNavigate } from "react-router";

import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import {
  CircularProgress,
  Collapse,
  IconButton,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableRowProps,
  TableSortLabel,
  Typography,
} from "@mui/material";

import { get } from "lodash";

import { ResourceError } from "@features/errors";
import { useFilterParams } from "@features/filters";

export type Column<R> = {
  id: string;
  label: ReactNode;
  align?: "left" | "right" | "center";
  sort?: string;
  render?: (cell: any, row: R, rowIndex: number) => ReactNode;
  renderText?: (cell: any, row: R) => string;
  to?: (row: R) => string | null;
};

export type PaginatedResourceProps = {
  isLoading?: boolean;
  isFetching?: boolean;
  hasNextPage?: boolean;
  isPlaceholderData?: boolean;
  error?: any;
  fetchNextPage?: () => void;
};

export type ReactQueryTableProps<R> = PaginatedResourceProps & {
  rows: R[];
  columns: Column<R>[];
  onRowClick?: (row: R) => void;
  noResultsText?: string;
  filterState?: Record<string, any>;
  setFilterState?: (x: Record<string, any>) => void;
  className?: string;
  maxHeight?: number;
  tableRef?: React.MutableRefObject<any>;
  rowCss?: (row: R) => any;
  tableSize?: "small" | "medium";
  rowProps?: (row: R) => TableRowProps;
  collapsibleContent?: (row: R) => ReactNode;
};

const splitSort = (str: string): ["asc" | "desc", string] =>
  str.startsWith("-") ? ["desc", str.slice(1)] : ["asc", str];

const TableHeader = ({
  columns,
  filterValues,
  setFilterValues,
  hasCollapsibleContent,
}) => {
  let { sort: sortParam } = filterValues;
  const [sortDirection, sortValue] = splitSort(sortParam ?? "");

  const handleSort = (value) => {
    if (sortValue === value) {
      const switchSortDirection = sortDirection === "desc" ? "" : "-";
      sortParam = switchSortDirection + value;
    } else {
      sortParam = "-" + value;
    }
    setFilterValues({ ...filterValues, sort: sortParam });
  };

  return (
    <TableHead
      css={{
        "&": tw`relative z-10`,
        th: tw`py-3 text-xs tracking-wider uppercase whitespace-nowrap text-neutral-500`,
      }}
    >
      <TableRow>
        {hasCollapsibleContent && <TableCell />}
        {columns.map(({ id, align, label, sort }) => (
          <TableCell key={id} align={align}>
            {sort ? (
              <TableSortLabel
                tw="w-full -mr-4"
                active={sort === sortValue}
                direction={(sort === sortValue && sortDirection) || "desc"}
                onClick={() => handleSort(sort)}
              >
                {label}
              </TableSortLabel>
            ) : (
              label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const ReactQueryTableRow = ({
  columns,
  onRowClick,
  collapsibleContent,
  row,
  rowCss,
  rowProps,
  rowIndex,
}) => {
  const [open, setOpen] = useState(false);
  const [renderContent, setRenderContent] = useState(false);
  const navigate = useNavigate();

  const handleNavigate = (url: string) => (e) => {
    if (e.ctrlKey || e.metaKey) {
      window.open(url, "_blank");
    } else {
      navigate(url, { state: { query: window.location.search } });
    }
  };
  return (
    <>
      <TableRow
        css={[
          tw`hover:bg-neutral-50`,
          onRowClick && tw`cursor-pointer`,
          rowCss?.(row),
        ]}
        onClick={() => onRowClick?.(row)}
        {...rowProps?.(row)}
      >
        {collapsibleContent && (
          <TableCell tw="align-middle!" padding="checkbox">
            <IconButton
              size="small"
              edge="end"
              tw="text-neutral-500 ml-3"
              onClick={(e) => {
                e.stopPropagation();
                setOpen((prev) => !prev);
                if (!renderContent) setRenderContent(true);
              }}
            >
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </TableCell>
        )}
        {columns.map((col) => {
          const cellUrl = col.to?.(row);
          return (
            <TableCell
              key={col.id}
              align={col.align ?? "left"}
              onClick={cellUrl ? handleNavigate(cellUrl) : undefined}
              css={cellUrl && tw`cursor-pointer`}
            >
              {col.render
                ? col.render(get(row, col.id), row, rowIndex)
                : get(row, col.id)}
            </TableCell>
          );
        })}
      </TableRow>
      {collapsibleContent && renderContent && (
        <TableRow tw="box-content">
          <TableCell
            // create new stacking context to prevent z-index issues
            tw="p-0 border-0 relative -z-0"
            colSpan={columns.length + 1}
          >
            <Collapse in={open} timeout="auto" tw="overflow-hidden w-full">
              <div tw="bg-neutral-100 py-4 px-6 shadow-inner border-b">
                {collapsibleContent(row)}
              </div>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

const ReactQueryTable = <R extends any>({
  rows,
  isLoading,
  isFetching,
  isPlaceholderData,
  hasNextPage,
  error,
  fetchNextPage,
  columns,
  onRowClick,
  noResultsText = "No results match your search...",
  filterState,
  setFilterState,
  className,
  maxHeight,
  tableRef,
  rowCss,
  tableSize,
  rowProps,
  collapsibleContent,
}: ReactQueryTableProps<R>) => {
  const [height, setHeight] = useState(0);

  // this is the default filter state
  const [filterParams, setFilterParams] = useFilterParams();

  const filterValues = filterState || filterParams;
  const setFilterValues = setFilterState || setFilterParams;

  const fetchNext = () =>
    !isLoading && !isFetching && hasNextPage && fetchNextPage?.();

  const scrollRef = useBottomScrollListener(fetchNext, {
    offset: 300,
    triggerOnNoScroll: true,
    debounceOptions: {
      leading: true,
      trailing: false,
    },
  }) as any;

  const heightRef = useCallback(
    (node: HTMLElement | null) => {
      if (tableRef) tableRef.current = node;
      if (maxHeight && node !== null) {
        const nodeHeight = node.getBoundingClientRect().height;
        setHeight(Math.min(nodeHeight, maxHeight));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [maxHeight, tableRef, rows]
  );

  useEffect(() => {
    if (isLoading || !scrollRef.current) return;
    scrollRef.current.scrollTop = 0;
  }, [isLoading, scrollRef]);

  if (isLoading && rows.length === 0) return <CircularProgress tw="m-6" />;

  return (
    <div tw="relative h-full overflow-hidden" className={className}>
      <TableContainer
        css={[
          tw`max-h-full`,
          isPlaceholderData &&
            tw`transition-opacity opacity-50 pointer-events-none`,
          maxHeight && { height: `${height}px` },
        ]}
        ref={scrollRef}
      >
        <Table
          stickyHeader
          css={{ td: tw`align-top` }}
          ref={heightRef}
          size={tableSize}
        >
          <TableHeader
            columns={columns}
            filterValues={filterValues}
            setFilterValues={setFilterValues}
            hasCollapsibleContent={Boolean(collapsibleContent)}
          />
          <TableBody>
            {!isLoading && rows.length === 0 && (
              <TableRow>
                <TableCell
                  colSpan={columns.length + (collapsibleContent ? 1 : 0)}
                >
                  <Typography>{noResultsText}</Typography>
                </TableCell>
              </TableRow>
            )}
            {rows.map((row, i) => (
              <ReactQueryTableRow
                key={i}
                columns={columns}
                onRowClick={onRowClick}
                collapsibleContent={collapsibleContent}
                row={row}
                rowCss={rowCss}
                rowProps={rowProps}
                rowIndex={i}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div tw="h-1 w-full absolute left-0 bottom-0">
        {isFetching && !isLoading && <LinearProgress />}
      </div>
      {error && (
        <div tw="absolute inset-0 bg-white/50 backdrop-blur-sm p-4 pt-16">
          <ResourceError error={error} />
        </div>
      )}
    </div>
  );
};

export default ReactQueryTable;
