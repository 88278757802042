/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { ReactNode } from "react";

import { Divider } from "@mui/material";

import { formatMoneyString } from "@utility/utilityFunctions";

import useCalculateOrderSetBudgetImpact from "./useCalculateOrderSetBudgetImpact";

const BudgetValue = ({
  name,
  value,
  className,
}: {
  name: ReactNode;
  value: ReactNode;
  className?: string;
}) => (
  <div tw="flex items-baseline justify-between text-base" className={className}>
    <div>{name}</div>
    <div>{value}</div>
  </div>
);

const BudgetImpactBreakdown = ({ budget }) => {
  const osImpact = useCalculateOrderSetBudgetImpact();
  const otherPending = +budget.pendingSpend - osImpact;
  return (
    <div tw="space-y-3">
      <Divider />
      <BudgetValue
        name="Starting Balance"
        value={formatMoneyString(budget.currentLevel)}
      />
      <div tw="space-y-1">
        {otherPending > 0 && (
          <BudgetValue
            name="Other Pending Orders"
            value={formatMoneyString(-otherPending)}
          />
        )}
        <BudgetValue
          name="This Order Set"
          value={formatMoneyString(-osImpact)}
        />
      </div>
      <BudgetValue
        name="Pending Balance"
        tw="font-medium"
        value={
          <span css={[+budget.pendingBalance < 0 && tw`text-red-700`]}>
            {formatMoneyString(budget.pendingBalance)}
          </span>
        }
      />
    </div>
  );
};

export default BudgetImpactBreakdown;
