/** @jsxImportSource @emotion/react */
import "twin.macro";
import tw from "twin.macro";

import { useLocation, useNavigate, useParams } from "react-router";

import { Dialog, IconButton, Tooltip } from "@mui/material";

import ErrorBoundary from "@components/Utility/ErrorBoundary";
import { useCurrentFilteredBoardConcepts } from "@features/concepts";
import { FaIcon } from "@features/ui";
import { useSetHash } from "@services/reactRouterDom";

import { ConceptProvider, useConceptContext } from "../ConceptContext";
import ConceptImageCarousel from "./ConceptImageCarousel";
import ConceptOverview from "./ConceptOverview";

const ConceptModalContent = () => {
  const concept = useConceptContext();
  const images = concept?.cloudinaryFiles.filter(
    (file) => file.resourceType === "image"
  );

  return (
    <div tw="relative overflow-y-auto max-h-full h-full p-3 grid xl:grid-cols-[auto 65ch] gap-6 rounded-xl transition-opacity">
      <div>
        {images.length > 0 ? (
          <div tw="rounded-lg bg-neutral-200 p-4 py-6 xl:(sticky top-0 h-[calc(100vh - 4.5em)])">
            <ConceptImageCarousel files={images} />
          </div>
        ) : (
          <div tw="flex flex-col h-[560px] justify-center items-center pointer-events-none">
            <div tw="text-center rounded-lg bg-neutral-100 p-8">
              <h2 tw="text-4xl text-primary-800 font-light">
                Upload Files and Images
              </h2>
              <p tw="mt-3 text-primary-900 text-lg">
                Add media and specs to help your team
                <br />
                understand your concept
              </p>
            </div>
          </div>
        )}
      </div>
      <div tw="py-4 pr-6 max-h-full">
        <ConceptOverview />
      </div>
    </div>
  );
};

const ConceptModal = () => {
  const { hash } = useLocation();
  const { boardId, conceptId: conceptIdParam } = useParams();
  const setHash = useSetHash();
  const navigate = useNavigate();
  const concepts = useCurrentFilteredBoardConcepts();
  const conceptId = hash.slice(1);

  const conceptIndex = concepts.findIndex(
    (concept) => concept.id === conceptId
  );
  const handleKeyDownEvent = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (conceptIndex === -1) return;
    if (e.key === "ArrowLeft") {
      const prevIndex =
        conceptIndex === 0 ? concepts.length - 1 : conceptIndex - 1;
      setHash(concepts[prevIndex].id);
    } else if (e.key === "ArrowRight") {
      const nextIndex =
        conceptIndex === concepts.length - 1 ? 0 : conceptIndex + 1;
      setHash(concepts[nextIndex].id);
    }
  };

  const open = Boolean(conceptId);

  return (
    <>
      <Dialog
        open={open}
        onClose={() => setHash(null)}
        fullScreen
        tw="pt-12"
        PaperProps={{
          style: tw`rounded-xl`,
        }}
        onKeyDown={handleKeyDownEvent}
        // Disable portal so that FullPageDropZone can overlay the modal
        disablePortal
      >
        <ErrorBoundary>
          <ConceptProvider conceptId={conceptId}>
            <ConceptModalContent />
          </ConceptProvider>
        </ErrorBoundary>
      </Dialog>
      {open && (
        <div tw="fixed top-2 right-4 flex gap-4 items-center z-[1900]">
          {!conceptIdParam && (
            <Tooltip title="Open Workspace">
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/create/boards/${boardId}`, { replace: true }); // set back-url
                  navigate(`/create/boards/${boardId}/${conceptId}`);
                }}
              >
                <FaIcon
                  icon="arrow-up-right-and-arrow-down-left-from-center"
                  tw="text-white"
                />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Close">
            <IconButton size="small" onClick={() => setHash(null)}>
              <FaIcon icon="xmark-large" tw="text-white" />
            </IconButton>
          </Tooltip>
        </div>
      )}
    </>
  );
};

export default ConceptModal;
