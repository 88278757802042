/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useDispatch, useSelector } from "react-redux";

import { Close } from "@mui/icons-material";
import { Dialog, DialogActions, IconButton } from "@mui/material";

import { clearItemPreview } from "@redux/slices/items/itemSlice";

import { ItemProvider } from "./ItemContext";
import ItemModalContent from "./ItemModalContent";

const ItemModal = () => {
  const dispatch = useDispatch();
  const { itemPreviewId: itemId } = useSelector((state: any) => state.items);

  const open = Boolean(itemId);

  const handleClose = () => dispatch(clearItemPreview());

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        disableScrollLock
        fullWidth
        maxWidth="lg"
        // keep mounted so that the portal node is not unmounted
        keepMounted
      >
        <IconButton
          tw="absolute top-1 right-1 z-10"
          onClick={() => {
            handleClose();
          }}
          size="large"
        >
          <Close color="secondary" />
        </IconButton>
        {itemId && (
          <ItemProvider itemId={itemId}>
            <ItemModalContent />
          </ItemProvider>
        )}
        <DialogActions tw="empty:hidden" id="item-modal-actions" />
      </Dialog>
    </>
  );
};

export default ItemModal;
