import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

import { useFilterParams } from "@features/filters";

import { useCurrentBoardConceptsQuery } from "./conceptQueries";

const useCurrentFilteredBoardConcepts = () => {
  const { conceptId } = useParams();
  const [params] = useFilterParams();
  const userSupplierId: string | undefined = useSelector(
    (state: any) => state.currentUser.supplier?.id
  );
  const { data: queryData } = useCurrentBoardConceptsQuery();

  const concepts = useMemo(() => {
    if (!queryData) return [];

    let data = queryData;

    // filter out cards with no content (no title or description)
    // if they don't belong to our group
    data = data.filter(
      (concept) =>
        concept.description ||
        concept.title ||
        concept.cloudinaryFiles.length > 0 ||
        userSupplierId === concept.ownerSupplier?.id
    );

    // filter concepts by parent concept id
    if (conceptId) {
      data = data.filter((concept) => concept.parentConceptId === +conceptId);
    } else {
      data = data.filter((concept) => !concept.parentConceptId);
    }

    if (params.favorite) {
      data = data.filter((concept) => concept.isFavorite);
    }

    if (params.q) {
      const terms: string[] = params.q.toLowerCase().split(" ").filter(Boolean);
      data = data.filter((concept) =>
        terms.every(
          (term) =>
            (concept.title ?? "").toLowerCase().includes(term) ||
            (concept.description ?? "").toLowerCase().includes(term)
        )
      );
    }

    if (params.suppliers) {
      data = data.filter((concept) =>
        concept.ownerSupplier
          ? params.suppliers.includes(concept.ownerSupplier.id)
          : concept.suppliers.some((supplier) =>
              params.suppliers.includes(supplier.id)
            )
      );
    }

    return data;
  }, [params, queryData, userSupplierId]);

  return concepts;
};

export default useCurrentFilteredBoardConcepts;
