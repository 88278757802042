/** @jsxImportSource @emotion/react */
import tw, { css } from "twin.macro";

import { useCallback, useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "react-router";

import { Card, LinearProgress, Table, TableContainer } from "@mui/material";

import { useCurrentOrderSet } from "../CurrentOrderSetContext";
import OrderSetHead from "./OrderSetHead";
import OrderSetOrders from "./OrderSetOrders";

const MIN_COL_WIDTH = 240;

// First part of the max() insures we have at least 260px of scrolling height for the orders in the table
// Second part caps the max height so that large enough screens don't cause the page to scroll
const orderSetTableStyles = css({
  "&": tw`max-h-[max(calc(var(--table-head-height) + 260px), calc(100vh - var(--header-height)) - 160px)]`,
  table: tw`border-separate`,
  "& > table > tbody > tr": tw`h-12 max-h-12`,
  "td, th": tw`
    bg-white border-r border-neutral-200 w-[240px] min-w-[240px]
    first-of-type:(w-[300px] min-w-[300px]! sticky left-0 z-20 bg-white)
    last-of-type:border-r-0`,
});

const OrderSetTable = () => {
  const { isFetching, orderSetVariants } = useCurrentOrderSet();
  const { hash } = useLocation();

  const formMethods = useForm<Record<string, any>>({
    defaultValues: {},
  });
  const [headerHeight, setHeaderHeight] = useState(0);
  // https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
  const measuredRef = useCallback((node: HTMLElement | null) => {
    if (node !== null) {
      setHeaderHeight(node.getBoundingClientRect().height);
    }
  }, []);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (hash) {
      const variantId = hash.slice(1);
      const variantColumnIndex =
        orderSetVariants.findIndex((osv) => osv.variant.id === variantId) ?? 0;

      containerRef.current?.scroll({
        left: variantColumnIndex * MIN_COL_WIDTH,
        behavior: "smooth",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash]);

  return (
    <div tw="pb-20 transition-opacity relative">
      {isFetching && <LinearProgress tw="absolute inset-0 bottom-auto" />}
      <Card
        tw="shadow-md overflow-hidden"
        style={{ opacity: isFetching ? 0.5 : 1 }}
      >
        <TableContainer
          ref={containerRef}
          css={orderSetTableStyles}
          style={{ "--table-head-height": `${headerHeight}px` } as any}
        >
          <Table size="small">
            <OrderSetHead measuredRef={measuredRef} />
            <FormProvider {...formMethods}>
              <OrderSetOrders />
            </FormProvider>
          </Table>
        </TableContainer>
      </Card>
    </div>
  );
};

export default OrderSetTable;
